import React from 'react';
import Icon from '../Icon/Icon';

function FieldHelpText({ ariaId, error, success, descriptionPosition, helpText }) {
  const position = !descriptionPosition || descriptionPosition === 'bottom';
  const helpTextId = ariaId ? `${ariaId}_text` : null;
  const errorId = ariaId ? `${ariaId}_error` : null;
  const errorMessages = Array.isArray(error) ? error : [error];
  return (
    <>
      {position && helpText && (
        <div id={helpTextId} className="text-xs italic text-gray-600">
          {helpText}
        </div>
      )}
      {error && (
        <div id={errorId} className="mt-1 text-xs italic text-red-700">
          {errorMessages.map((errorMessage) => {
            return (
              <p className="mb-0" key={`${errorId}-${errorMessage}`}>
                {errorMessage}
              </p>
            );
          })}
        </div>
      )}
      {success && (
        <div className="flex gap-0.5 justify-start items-center mt-1 text-xs italic font-medium text-green-500">
          <Icon name="check" className="w-4 h-4" />
          {success}
        </div>
      )}
    </>
  );
}

export default FieldHelpText;
