import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import UpgradeButton from '../../../components/UpgradeButton/UpgradeButton';
import Button from '../../../components/Button/Button';

/**
 * Upgrade or Continue Interstitial
 * @param {boolean} canContinue - Whether the user can continue without upgrading
 * @param {string} cybSource - The data source of the upgrade button for tracking
 * @returns {JSX.Element}
 */
function InterstitialUpgradeOrContinueButtons({ handleClose = () => {}, canContinue = false, cybSource = 'ii-default', className = '' }) {
  const containerClasses = useMemo(() => twMerge('flex justify-evenly items-center mx-auto mt-8 w-full max-w-xs', className), [className]);
  return (
    <div className={containerClasses}>
      <UpgradeButton color="pink" upgradeText="Upgrade" buttonClasses="text-lg" upgradeLinkParams={`?cybsource=${cybSource}`} />
      <span className="mx-6 text-sm">Or</span>
      <Button color="gray" disabled={!canContinue} title={!canContinue ? 'Upgrade to continue now' : ''} className="text-lg" onClick={canContinue ? handleClose : null}>
        Continue
      </Button>
    </div>
  );
}

export default InterstitialUpgradeOrContinueButtons;
