import React from 'react';
import UpgradeButton from '../../../components/UpgradeButton/UpgradeButton';
import HelpDeskTrigger from '../../../components/HelpDeskTrigger/HelpDeskTrigger';
import { HELP_DESK_LINKS } from '../../../constants';

function InterstitialUpgradeButtonFooter({ cybSource = 'ii-default' }) {
  return (
    <div className="flex flex-col justify-center my-4">
      <UpgradeButton color="pink" upgradeText="Upgrade Now" upgradeLinkParams={`?cybsource=${cybSource}`} buttonClasses="text-lg" />
      <div className="flex justify-center items-center mt-2 text-xs italic text-gray-700">
        Upgrade for as low as $0.99/day! <HelpDeskTrigger to={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE} className="ml-1" />
      </div>
      <div className="my-4 border-b border-gray-300" />
      <UpgradeButton color="transparent-gray" upgradeText="Still Not sure? View All Plans" upgradeLinkParams={`?cybsource=${cybSource}`} />
    </div>
  );
}

export default InterstitialUpgradeButtonFooter;
