/**
 * We are going to run a holiday promo starting on 12/23 through 01/02.

Target audience: all free users

Banner Copy: TK

For this offer, we aren’t going to use a link to push the discount to Checkout. Instead we will show the code in the banner.

Discount details:

Discount for: cybernewyear35
Discount: 35% off, recurring
Expiration: N/A
Applies to: CIP Annual Only
Checkout Code: cybernewyear35
Discount Id: dsc_01jf07x0av6q17ff4x2axn69r9

https://cybrary.atlassian.net/jira/software/c/projects/PD/boards/24?selectedIssue=PD-3207

 */

import React from 'react';

const CYBER_NEW_YEAR_PROMO = {
  // Enable or disable the promo
  isEnabled: true, // Default: false

  // Snowplow tracking labels
  spLabel: 'cyberNewYearPromo', // Required - the label/key of the promo
  spCategory: 'CIPUpgradeBanner', // Required - The type of promo

  // Show the promo based on the user's role/state
  isShownCipFree: true, // subscription = 'free' - Default: false

  // Configure the attributes of the banner
  title: 'Save 35% on a CIP subscription today!',
  body: (
    <>
      Use code <span className="px-1 font-mono text-sm font-black text-gray-800 bg-gray-200 rounded-md">cybernewyear35</span> at checkout to save{' '}
      <span className="text-lg font-bold">35%</span> on a CIP subscription today!
    </>
  ),
  footer: 'Valid until January 2nd. Hurry, this offer ends soon!',
  iconName: 'rocket', // Optional - Default: 'unlock' @see https://app.blackwaterbay.cybrary.it/icons for available icons

  // Configure CTA Link
  targetUrl: `/upgrade/checkout/annual`, // Default: null

  // (Optional) Priority of the promo. Higher number = shown first.
  priority: 5, // Default: 1

  // (Optional) Start date of the promo
  startDate: '2024-12-23T00:00:00-05:00', // Default: Disabled
  // (Optional) Expiration date of the promo
  endDate: '2025-01-03T00:00:00-05:00', // Default: Disabled
};

export default CYBER_NEW_YEAR_PROMO;
