import React, { useState, useEffect, useMemo } from 'react';
import Collapsible from 'react-collapsible';
import RadioGroup from '../FormFields/RadioButtons';
import CheckboxGroup from '../DynamicForm/CheckboxGroup';
import Input from '../FormFields/Input';
import FieldHelpText from '../FormFields/FieldHelpText';
import Agents from '../../agents/agents';
import Loading from '../Loading/Loading';
import FormatUtil from '../../utils/formatUtil';
import ChallengeAssessment from './Content/Assessment/ChallengeAssessment';
import { useImmersiveTasks } from '../../providers/ImmersiveTasksProvider';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import If from '../If/If';

function ChallengeBlock({ children, buttonText, buttonOnClick, buttonClassname, buttonDisabled = false }) {
  const buttonClasses = buttonClassname || 'w-full text-lg rounded-md font-semibold py-2 hover:bg-pink-600 text-white disabled:bg-gray-500 disabled:cursor-not-allowed';
  return (
    <div className="p-4">
      <div className="flex overflow-hidden relative flex-col py-5 px-3 h-full text-gray-800 bg-gray-100 rounded-sm border border-gray-400">
        <div className="box-content flex-1 pb-6 w-full h-full">{children}</div>
        <If condition={buttonText}>
          <Button color="pink" className={buttonClasses} onClick={buttonOnClick} disabled={buttonDisabled}>
            {buttonText}
          </Button>
        </If>
      </div>
    </div>
  );
}

function ChoiceWrapper({ type, defaultValue, name, description, onChange, formattedOptions, error, buttonType }) {
  if (type !== 'choice') {
    return null;
  }
  if (buttonType === 'checkbox') {
    return (
      <div>
        <div className="mb-4 text-sm font-bold">(select all that apply)</div>
        <CheckboxGroup
          defaultValue={defaultValue}
          id={name}
          name={name}
          labelClasses="text-gray-800"
          optionClassName="mb-0"
          wrapperClassName="block my-0"
          description={description}
          onChange={onChange}
          options={formattedOptions}
          fieldsPerRow={1}
          error={error}
        />
      </div>
    );
  }
  // default radio button type
  return (
    <div>
      <RadioGroup
        defaultValue={defaultValue && Array.isArray(defaultValue) && defaultValue.length ? defaultValue[0] : defaultValue}
        id={name}
        name={name}
        className="block my-2"
        labelClassName="text-gray-800"
        description={description}
        onChange={onChange}
        options={formattedOptions}
        error={error}
      />
    </div>
  );
}

function AsteriskAnswerHint({ hint }) {
  if (!hint) {
    return null;
  }
  return (
    <p className="mt-4 ml-1 text-gray-800">
      <Collapsible
        trigger={
          <span className="flex gap-1 items-center text-sm italic font-medium text-gray-700">
            Show Hint
            <Icon name="chevron-right" className="w-4 h-4 text-cyb-pink-500" />
          </span>
        }
        triggerWhenOpen={
          <span className="flex gap-1 items-center text-sm italic font-medium text-gray-700">
            Hide Hint
            <Icon name="chevron-down" className="w-4 h-4 text-cyb-pink-500" />
          </span>
        }
        triggerClassName="font-medium text-gray-700 italic text-sm"
        triggerOpenedClassName="font-medium text-gray-700 italic text-sm"
      >
        <div className="rounded-md">
          <pre className="pt-2 rounded-md">
            <code>{hint?.replace(/\*/g, '•')}</code>
          </pre>
        </div>
      </Collapsible>
    </p>
  );
}

function EmptyChallengeArea({ message }) {
  return <p className="mb-6 text-base">1. {message}</p>;
}

// Standardize the key lookup for a question (a<num>)
function getKey(i) {
  return `a${i}`;
}

// Return an array of server errors from a server error response object (resulting from a 422, always meaning required data missing)
function extractServerErrors(errors) {
  const ret = [];
  const keys = Object.keys(errors);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const realKey = key.replace('answers.', '');
    ret.push({
      key: getKey(realKey),
      error: 'You must provide an answer to this question.',
    });
  }
  return ret;
}

// Provide a score for the challenge questions, returning the number wrong and an object of errors.
async function scoreChallenge(activity, questions, values) {
  let numWrong = 0;
  const newErrors = {};
  if (!questions || !questions.length) {
    return [numWrong, newErrors];
  }

  const answersArray = [];
  for (let i = 0; i < questions.length; i++) {
    answersArray.push(values?.[getKey(i)]);
  }

  try {
    const answersResult = await Agents.catalog.answers(activity.id, { answers: answersArray });
    const { answers } = answersResult;
    // Loop over the answers and see if all are true or not
    for (let i = 0; i < answers.length; i++) {
      if (!answers[i]) {
        const key = getKey(i);
        // This one is wrong, set the error!
        newErrors[key] = `Please retry and hit 'Submit' to continue.`;
        numWrong++;
      }
    }
  } catch (err) {
    // We encountered an error checking answers, this happens when data is NOT provided
    const { response: errorResponse } = err;
    const { status, data: errorData } = errorResponse || {};
    if (status === 422) {
      // The only validation done is 'required' so we know the error message to display, extract the keys of the fields that have errors and set them
      const invalidErrors = extractServerErrors(errorData.errors);
      numWrong = invalidErrors.length;
      for (let i = 0; i < invalidErrors.length; i++) {
        const { key: k, error: errorMessage } = invalidErrors[i];
        newErrors[k] = errorMessage;
      }
    } else {
      // Some sort of unknown error, alert the user.. (should not happen)
      numWrong = 1;
      newErrors[getKey(0)] = 'An unknown error occurred, please try again.';
    }
  }
  return [numWrong, newErrors];
}

// Return the number of wrong answers
async function numberWrongAnswers(activity, questions, values, setErrors) {
  const [numWrong, newErrors] = await scoreChallenge(activity, questions, values);
  setErrors(newErrors);
  return numWrong;
}

// Actual submit handler logic
async function handleSubmit({ activity, questions, values, isComplete, isCompletedRecently, setSubmitting, setErrors, completeActivity, next, showSuccessBar }) {
  if (isComplete || isCompletedRecently) {
    return next();
  }
  if (!questions?.[0]?.label) {
    setSubmitting(true);
    await completeActivity();
    setSubmitting(false);
    return null;
  }
  const numWrong = await numberWrongAnswers(activity, questions, values, setErrors);
  if (Number(numWrong) === Number(0)) {
    setSubmitting(true);
    const res = await completeActivity();
    setSubmitting(false);
    if (res && res.skip) {
      return next();
    }
    return showSuccessBar();
  }
  return null;
}

function optionOrdering(i) {
  const index = i + 1;
  return String.fromCharCode(64 + index);
}

function ChallengeContent({ question, defaultValue, onChange, error, successMessage, description, name, order }) {
  if (!question || typeof question !== 'object' || !question.label) {
    return <EmptyChallengeArea />;
  }
  const { type, options, label } = question;
  const formattedOptions =
    options && options.length
      ? options.map((opt, i) => {
          const alphabetOrder = optionOrdering(i);
          return {
            value: opt.value,
            text: `${alphabetOrder}. ${opt.label}`,
          };
        })
      : [];
  return (
    <div className="py-6 pb-4 mx-6 last:border-0 border-b-xs border-gray-400">
      <h4 className="mb-4 font-semibold break-words">{`${order}. ${label}`}</h4>
      {type === 'text' && (
        <>
          <Input defaultValue={defaultValue} id={name} name={name} required placeholder="Type your answer here" onChange={onChange} error={error} />
          {!successMessage && <AsteriskAnswerHint hint={question.hint} />}
        </>
      )}
      <ChoiceWrapper
        type={type}
        buttonType={question.buttonType}
        defaultValue={defaultValue}
        name={name}
        description={description}
        onChange={onChange}
        formattedOptions={formattedOptions}
        error={error}
      />
      {!!error && <FieldHelpText error={error} />}
      {!!successMessage && <FieldHelpText success={successMessage} />}
    </div>
  );
}

// Helper function to only set answers IF isMounted is true
function maybeSetAnswers(setAnswers, isMounted, value) {
  if (isMounted) {
    setAnswers(value);
  }
}

export default function Challenge({
  activity,
  questions,
  showSuccessBar,
  completeActivity,
  isComplete,
  gotoNextActivity,
  isLast,
  isLastIncomplete,
  gotoCompletePage,
  completedActivityId,
  isDoubleSidebar,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [answers, setAnswers] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [isThirdParty, setIsThirdParty] = useState(false);
  const [isChallengeAssessment, setIsChallengeAssessment] = useState(false);
  const [normalizedType, setNormalizedType] = useState(null);
  const [isCompletedRecently, setIsCompletedRecently] = useState(false);
  const { values, setValues } = useImmersiveTasks();

  useEffect(() => {
    let isMounted = true;
    async function init() {
      // Check to see if this is a cybrary assessment replacing challenge questions
      const { hasCybAssessment, cybAssessmentId } = activity;
      if (hasCybAssessment && cybAssessmentId) {
        setIsChallengeAssessment(true);
        setLoading(false);
        return;
      }

      if (isComplete) {
        // If this is a completed activity, let's fetch the answers!
        const { id } = activity;
        try {
          const result = await Agents.catalog.getAnswers(id);
          maybeSetAnswers(setAnswers, isMounted, result);
        } catch (e) {
          maybeSetAnswers(setAnswers, isMounted, null);
        }
      }
      setLoading(false);
    }
    init();
    // Prevent state updating if the component is unmounted before the init function is finished.
    return () => {
      isMounted = false;
    };
  }, [activity, isComplete]);

  useEffect(() => {
    const { type, id: activityId } = activity;
    const newIsVideo = type === 'Video Activity';
    const newIsCompletedRecently = isComplete || completedActivityId === activityId;
    setIsVideo(newIsVideo);
    setIsCompletedRecently(newIsCompletedRecently);
    const newNormalizedType = FormatUtil.formatContentType(type);
    setNormalizedType(newNormalizedType);
    setIsThirdParty(['Virtual Lab', 'Assessment', 'Practice Test'].includes(newNormalizedType));
  }, [activity, completedActivityId, isComplete]);

  const onChange = (e, data, i) => {
    e?.preventDefault?.();
    const { value } = data;
    const key = getKey(i);
    const currentValue = values?.[key];

    if (currentValue !== value) {
      setValues({
        ...values,
        [key]: value,
      });
    }
  };

  // Go to the next activity, or complete page, depending on whether this is the last lesson or not
  const next = () => {
    if (isLast) {
      gotoCompletePage(); // Let complete page handle user flow from here
      return;
    }
    gotoNextActivity();
  };

  const submitHandler = (e) => {
    e?.preventDefault?.();
    return handleSubmit({ activity, questions, values, isComplete, isCompletedRecently, setSubmitting, setErrors, completeActivity, next, showSuccessBar });
  };

  // This will only be shown when the video is complete, or was just completed by reaching the end.
  const legacyTypeSubmitHandler = (e) => {
    e?.preventDefault?.();
    return next();
  };

  const getCorrectAnswers = (question, defaultValue) => {
    const correctAnswers = defaultValue;
    if (Array.isArray(defaultValue)) {
      return question && question.type === 'choice' && question.buttonType === 'checkbox' ? correctAnswers : correctAnswers[0];
    }
    return correctAnswers;
  };

  const containsQuestions = !!questions?.[0]?.label;
  const btnText = useMemo(() => {
    if (isCompletedRecently || isComplete) {
      return 'Continue';
    }
    if (submitting) {
      return 'Submitting...';
    }
    if (!containsQuestions) {
      return 'Mark as Complete';
    }
    if (isLast && isLastIncomplete) {
      return 'Complete';
    }
    return 'Submit';
  }, [isCompletedRecently, containsQuestions, isLast, isLastIncomplete, submitting]);

  const successMessage = isComplete || isCompletedRecently ? 'Correct!' : undefined;

  if (loading) {
    return (
      <div className="mt-8">
        <Loading />
      </div>
    );
  }

  if (isChallengeAssessment) {
    const { cybAssessmentId } = activity;
    return (
      <div className="sticky">
        <ChallengeAssessment completeActivity={completeActivity} activity={activity} id={cybAssessmentId} isDoubleSidebar={isDoubleSidebar} />
      </div>
    );
  }

  // Much like with a video, if this is a third party type, just return a continue or incomplete button
  if (isThirdParty) {
    return (
      <ChallengeBlock buttonText={isComplete ? 'Continue' : 'Incomplete'} buttonOnClick={isComplete ? legacyTypeSubmitHandler : null} buttonDisabled={!isComplete}>
        <p className="mb-4 text-base">1. Complete the {normalizedType}.</p>
        <p className="mb-6 text-base">Your results will be posted within 24 hours.</p>
      </ChallengeBlock>
    );
  }

  // If this is a video, we are showing a simple box attached to the completeness of the activity
  if (isVideo) {
    return (
      <ChallengeBlock
        buttonText={isCompletedRecently ? 'Continue' : 'Incomplete'}
        buttonOnClick={isCompletedRecently ? legacyTypeSubmitHandler : null}
        buttonDisabled={!isCompletedRecently || submitting}
      >
        <EmptyChallengeArea message="Watch the video." />
      </ChallengeBlock>
    );
  }

  // Some Reading challenges have an empty question object, so we need to check for that
  const isReadingChallenge = !questions?.[0]?.label;

  if (isReadingChallenge) {
    return (
      <ChallengeBlock buttonText={btnText} buttonOnClick={submitHandler}>
        <EmptyChallengeArea message="Read the lesson." />
      </ChallengeBlock>
    );
  }

  // Check if all questions have been answered
  const hasAnsweredAllQuestions = Object.values(values).every((value) => !!value) && Object.keys(values).length === questions.length;
  return (
    <ChallengeBlock buttonText={btnText} buttonOnClick={submitHandler} buttonDisabled={(submitting || !hasAnsweredAllQuestions) && !isComplete && !isCompletedRecently}>
      {questions.map((q, i) => {
        const defaultValue = answers?.[i];
        const name = getKey(i);
        const value = values?.[name];
        const error = errors?.[name];
        const description = error || isComplete ? '' : 'Choose the best answer';
        const order = i + 1;
        return (
          <ChallengeContent
            key={name}
            name={name}
            order={order}
            question={q}
            defaultValue={getCorrectAnswers(q, defaultValue) || value}
            onChange={(e, data) => {
              onChange(e, data, i);
            }}
            error={error}
            successMessage={successMessage}
            description={description}
          />
        );
      })}
    </ChallengeBlock>
  );
}
