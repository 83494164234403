import React from 'react';
import { twMerge } from 'tailwind-merge';
import Collapsible from '../Collapsible/Collapsible';

/**
 * Collapsible code block for examples
 * @param {string|JSX} children - the code to be displayed
 * @returns {JSX.Element} - the collapsible code block
 */
function Code({ children, title = 'Show Code', className = '' }) {
  const classes = twMerge('rounded-md border border-gray-200', className);
  return (
    <div className="flex flex-col gap-1.5 pb-8 my-2">
      <Collapsible trigger={<span className="text-sm font-medium text-gray-500">{title}</span>}>
        <div className={classes}>
          <pre className="p-2 rounded-md language-javascript">
            <code className="language-javascript">{children}</code>
          </pre>
        </div>
      </Collapsible>
    </div>
  );
}

export default Code;
