/**
 * Some logic that gets repeated surrounding enrollments.
 */
import Bugsnag from '@bugsnag/js';
import GaUtil from './gaUtil';
import { WWW_PRICING_PAGE } from '../constants';

export default class EnrollmentUtil {
  /**
   * Handle start enrollment logic, consolidating it since it appears in various places.
   * @param {UserStore} userStore - a reference to the user store.
   * @param {EnrollmentStore} enrollmentStore - a reference to the enrollment store.
   * @param {CommonStore} commonStore - a reference to the common store.
   * @param {any} navigate - Reference to navigate method, for redirecting after success.
   * @param {any} item - the item we are enrolling into
   * @param {any} data - the data we are sending along with the enrollment, from an enrollment form.  May be undefined.
   * @param {any} options.preferredEnrollmentId - preferred enrollmentId.  May be undefined.
   * @returns void - no return value.
   */
  static handleStartEnroll = async (userStore, enrollmentStore, commonStore, navigate, item, data = {}, options = {}) => {
    const { team } = userStore;
    const preferredEnrollmentId = options?.enrollmentId || null;
    const preferredCollectionItemId = options?.preferredCollectionItemId || null;
    // Check for permissions
    if (userStore.checkPermissions(item)) {
      const type = !!item && item.content_type && item.content_type.nice_name ? item.content_type.nice_name : null;
      // If the user is allowed to enroll in the preferred item, do so
      if (preferredEnrollmentId) {
        if (preferredCollectionItemId) {
          navigate(`/immersive/${preferredEnrollmentId}/item/${preferredCollectionItemId}`);
          return;
        }
        navigate(`/immersive/${preferredEnrollmentId}`);
        return;
      }
      try {
        const responseInfo = await enrollmentStore.startEnrollment(item.id, data, type);
        const { enrollmentId } = responseInfo;
        GaUtil.fireEvent(item.content_type.nice_name, 'Enrollment', item.title);
        const isActivity = !!responseInfo && responseInfo.enrollment && responseInfo.enrollment.is_activity;
        // Instead of onboarding, just jump to the enrollments page and tell it to launch the freshly enrolled item!
        navigate(
          `/enrollments-launcher?launch=${enrollmentId}${isActivity ? '&isActivity=true' : ''}${
            preferredCollectionItemId ? `&preferredCollectionItemId=${preferredCollectionItemId}` : ''
          }`
        );
      } catch (error) {
        Bugsnag.notify(error);
      }
    } else if (!!team && team.id) {
      // If the user is enterprise, send them to request a license rather than upgrade
      window.location.href = commonStore.getRequestLicenseLink(team.id);
    } else if (item.content_type.name === 'career_path') {
      // Redirect to Upgrade page
      const nameOfPath = item.permalink.split('/')[1];
      window.location.href = `${WWW_PRICING_PAGE}?appbrowse=collection&content=${nameOfPath}`;
    } else {
      window.location.href = `${WWW_PRICING_PAGE}?appbrowse=${item.content_type.name.toLowerCase().replace(' ', '-')}`;
    }
  };

  static isInternalActivity = (type) => {
    const internalActivities = [
      'Course',
      'MicroCourse',
      'Video Activity',
      'Course Module',
      'Article Activity',
      'Curriculum',
      'Career Path',
      'Assessment',
      'Assessment Path',
      'Cybrary Live Series',
      'Cybrary Live Session',
      'Threat Actor Path',
      'Cybrary Select',
      'Collection',
      'Virtual Lab',
      'Challenge',
      // Below items are not 'internal', but treated as such
      'Practice Labs Lab',
      'Practice Labs Lab Module',
      'Next Tech Lab Module',
      'Next Tech Lab',
      'Next Tech Assessment',
      'Next Tech Practice Test',
      'Next Tech Article',
      'Avatao Course',
    ];
    return internalActivities.indexOf(type) > -1;
  };

  static isCollection = (type) => {
    return ['Curriculum', 'Career Path', 'Threat Actor Path', 'Cybrary Select'].includes(type);
  };

  static isCourse = (type) => {
    return ['Course', 'MicroCourse', 'Virtual Lab'].includes(type);
  };

  /**
   * A course or content collection can both be considered Cybrary Select
   * Check the type of the content collection, and check the tags of a course
   * @param {Object} item A course or content collection
   * @returns true or false
   */
  static isCybrarySelect = (item) => item?.content_type?.name === 'cybrary_select_path' || item?.tags_info?.includes('Cybrary Select');

  static isCustomPath = (item) => item?.content_type?.name === 'curriculum';
}
