import React from 'react';
import { Userpilot } from 'userpilot';
import { twMerge } from 'tailwind-merge';
import { PAYMENT_METHODS, USERPILOT_EVENTS } from '../../constants';
import AddLink from '../../components/AddLink/AddLink';

const PAYMENT_METHOD_CARD_MAPPING = Object.entries(PAYMENT_METHODS.card);

const PAYMENT_METHOD_ALT_MAPPING = Object.entries(PAYMENT_METHODS.alternative);

const PAYMENT_METHOD_INTL_MAPPING = Object.entries(PAYMENT_METHODS.international);

const UNLISTED_PAYMENT_METHOD_CTA = () => {
  // fire UP event to collect user's preferred payment method(s) if unlisted
  Userpilot.track(USERPILOT_EVENTS.CHECKOUT_UNLISTED_PAYMENT_METHOD);
};

function PaymentBadges({ className }) {
  return (
    <div className={twMerge(`flex flex-col justify-center mx-auto w-full text-center`, className)}>
      <div className="mb-5 text-lg font-bold text-center front-black">We Accept International Payments!</div>
      <div className="flex flex-row mb-2 ml-2">
        {PAYMENT_METHOD_CARD_MAPPING.map(([key, value]) => {
          let wrapperClasses = 'md:px-4 lg:px-2';
          let imageClasses = 'lg:w-auto';

          if (key === 'AMERICAN_EXPRESS' || key === 'MASTERCARD') {
            wrapperClasses = 'md:px-0 lg:px-5';
            imageClasses = 'md:w-10';
          }

          return (
            <div className="flex relative justify-center items-center mr-2 w-1/4 h-12 rounded-md border-1 border-gray-400 md:h-14" key={key}>
              <div className={twMerge('w-full', wrapperClasses)}>
                <img className={twMerge('mx-auto', imageClasses)} alt={`${value.label}-icon`} src={value.logo} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-row mb-2 ml-2">
        {PAYMENT_METHOD_ALT_MAPPING.map(([key, value]) => {
          let imageClasses = 'lg:w-12 md:w-14';

          if (key === 'PAYPAL') {
            imageClasses = 'lg:w-16 md:w-20';
          }

          if (key === 'BANCONTACT') {
            imageClasses = 'lg:w-16 md:w-20';
          }

          return (
            <div className="flex relative justify-center items-center mr-2 w-1/4 h-12 rounded-md border-1 border-gray-400 md:h-14" key={key}>
              <div className="w-full">
                <img className={twMerge('mx-auto', imageClasses)} alt={`${value.label}-icon`} src={value.logo} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-row p-2 pr-1 ml-1 bg-gray-200 rounded-lg">
        {PAYMENT_METHOD_INTL_MAPPING.map(([key, value]) => {
          let imageClasses = 'lg:w-10 md:w-12';

          if (key === 'MAESTRO') {
            imageClasses = 'lg:w-10 md:w-10';
          }

          if (key === 'DINERS_CLUB') {
            imageClasses = 'lg:w-14 md:w-14';
          }

          return (
            <div className="flex relative justify-center items-center mr-1 w-1/5 h-10 bg-white rounded-md border-1 border-gray-300 md:h-12" key={key}>
              <div className="w-full">
                <img className={twMerge('mx-auto', imageClasses)} alt={`${value.label}-icon`} src={value.logo} />
              </div>
            </div>
          );
        })}
      </div>
      <AddLink onClick={UNLISTED_PAYMENT_METHOD_CTA} className="pt-4 text-xs font-bold text-neutral-400 hover:opacity-80 active:opacity-50">
        My payment provider is not listed
      </AddLink>
    </div>
  );
}
export default PaymentBadges;
