import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Container from '../Container/Container';

/**
 * GradientBanner component
 * @param {string} className - The class name for the component
 * @param {React.ReactNode} children - The content to be displayed inside the banner
 * @param {string} backgroundImageUrl - The URL of the background image
 * @param {number} backgroundImageFade - The opacity of the background image fade. 0.6 = 60% opacity black overlay
 * @returns {React.ReactNode} The GradientBanner component
 */
function GradientBanner({ className, children, backgroundImageUrl, backgroundImageFade = 0.6 }) {
  const hoverClasses = 'transition transition-all duration-[2000ms] ease-in-out bg-[length:110%_110%] hover:bg-[length:200%_200%] bg-left-bottom hover:bg-right-bottom';
  const gradientClasses = `bg-gradient-to-r from-[#CF0469] to-[#25135F] ${hoverClasses}`;

  const classes = useMemo(
    () =>
      twMerge(
        `group p-1 sm:p-2 mx-auto w-full text-white font-bold min-h-[80px] flex item-center max-h-[25vh] overflow-hidden`,
        backgroundImageUrl ? `bg-no-repeat bg-center bg-cover bg-black/${backgroundImageFade * 100} bg-blend-overlay` : gradientClasses,
        className
      ),
    [className, backgroundImageUrl]
  );

  const style = backgroundImageUrl ? { backgroundImage: `url(${backgroundImageUrl})` } : {};

  return (
    <div className={classes} style={style}>
      <Container size="xl-1680" className="items-center px-0 text-center md:flex" omitPadding>
        {children}
      </Container>
    </div>
  );
}

export default GradientBanner;
