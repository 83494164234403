import React from 'react';
import moment from 'moment';
import DropdownFilter from '../Dropdown/DropdownFilter';
import DateRangeOptions from './DateRangeOptions';
import DatePicker from '../DatePicker/DatePicker';
import If from '../If/If';

class DateRangeFilter extends React.Component {
  // Returns array of date range dropdown options
  getDateRangeOptions() {
    const opts = this.props.showGroupCreationFilter ? Object.keys(DateRangeOptions) : Object.keys(DateRangeOptions).filter((option) => option !== 'groupCreation');

    return opts.map((option) => {
      return {
        key: option,
        text: DateRangeOptions[option].text,
        value: option,
      };
    });
  }

  dateRangeChange = ({ value }) => {
    if (value !== this.props.dateRange) {
      const startDate = value === 'allTime' && this.props.allTimeStart ? moment(this.props.allTimeStart) : DateRangeOptions[value].startDate;
      const { endDate } = DateRangeOptions[value];
      this.props.dateChange(startDate, endDate, value);
    }
  };

  startDateChange = (date) => {
    if (moment(date) !== moment(this.props.startDate)) {
      // Prevent the start date from being greater than the end date
      const newDate = moment(date) > moment(this.props.endDate) ? this.props.endDate : date;
      this.props.dateChange(newDate, this.props.endDate);
    }
  };

  endDateChange = (date) => {
    if (date && moment(date) !== moment(this.props.endDate)) {
      // Prevent the end date from being greater than today
      const newDate = moment(date) < moment(this.props.startDate) ? this.props.startDate : date;
      this.props.dateChange(this.props.startDate, newDate);
    }
  };

  render() {
    const { disabled, omitDateRange, rangeSelectionClasses, dateSelectionWrapperClasses, dateRange, startDate, endDate, isCompact, isPresetOnly } = this.props;
    let wrapperClasses = dateSelectionWrapperClasses || 'flex items-center';

    if (isCompact) {
      wrapperClasses += ' compact-date-wrapper';
    }

    return (
      <>
        <If condition={!omitDateRange}>
          <div className={rangeSelectionClasses}>
            <DropdownFilter
              name="date-range"
              options={this.getDateRangeOptions()}
              value={dateRange}
              onChange={this.dateRangeChange}
              placeholder="Select Date Range"
              ariaLabel="Select date range"
              disabled={disabled}
              isCompact={isCompact}
            />
          </div>
        </If>
        <If condition={!isPresetOnly}>
          <div className={wrapperClasses}>
            <DatePicker
              id="start-date"
              value={startDate}
              onChange={this.startDateChange}
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
              label="Start Date"
              disabled={disabled}
            />
            <span className="mx-4">to</span>
            <DatePicker
              id="end-date"
              value={endDate}
              onChange={this.endDateChange}
              direction="left"
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
              label="End Date"
              disabled={disabled}
            />
          </div>
        </If>
      </>
    );
  }
}

export default DateRangeFilter;
