import React, { useState, useEffect } from 'react';
import AddLink from '../AddLink/AddLink';
import HamburgerMenu from '../Navigation/Icons/HamburgerMenu';
import PercentageBar from '../Analytics/PercentageBar';
import Outline from './Outline';
import Icon from '../Icon/Icon';
import TabsMenu from './TabsMenu';
import PreviousNextButtons from './PreviousNextButtons';
import Modal from '../Modal/Modal';
import UserStatsHeader from './UserStatsHeader';
import { useForumsSidebar } from '../../providers/ForumsSidebarProvider';
import Loading from '../Loading/Loading';
import If from '../If/If';
import Button from '../Button/Button';
import { useImmersive } from '../../providers/ImmersiveProvider';

function DesktopPrimaryNavigation({
  title,
  progress,
  togglePrimarySidebarDisplay,
  enableHamburgerMenu,
  activity,
  previous,
  next,
  completedActivityId,
  navigate,
  isLast,
  isItemLastActivity,
  gotoCompletePage,
  emptyChallenge,
  completeActivity,
  activeInterstitial,
  setActiveInterstitial,
  hasIncompleteItems,
  canAccessActivity,
  desktopTabMenu,
  isPrimarySidebarOpen,
  setIsPrimarySidebarOpen,
  isMobile,
  disableSidebar,
  setDisableSidebar,
  runningClab,
  toggleClabModal,
  disableNav = false,
  onCompletePage = false,
}) {
  const [showOutline, setShowOutline] = useState(false);
  const { open, isLoading } = useForumsSidebar();
  const { outlineContainerRef, collectionItemContainerRefs, moduleContainerRefs, discourseSlug } = useImmersive();

  const maybeShowModal = () => {
    if (runningClab) {
      toggleClabModal();
      return;
    }
    navigate('/');
  };

  const toggleOutline = () => {
    setShowOutline(!showOutline);
  };

  useEffect(() => {
    // Hide and disable the sidebar when entering an interstitial
    // Re-enabling of the sidebar is handled in the interstitial component and next buttons
    // This is to prevent the sidebar from being opened when the page is initially
    // loaded since this hook runs on every first mount.
    if (activeInterstitial && isPrimarySidebarOpen) {
      setIsPrimarySidebarOpen(false);
      setDisableSidebar(true);
    }
  }, [activeInterstitial, isPrimarySidebarOpen]);

  // Clear ref to outlineContainerRef when unmounting
  // This is to prevent memory leaks when the outline is closed
  useEffect(() => {
    if (!showOutline) {
      collectionItemContainerRefs.current = [];
      moduleContainerRefs.current = [];
    }
  }, [showOutline]);

  const isAssessmentType = activity?.isCybAssessment || activity?.hasCybAssessment;

  return (
    <>
      <div className="hidden justify-between items-center w-full h-16 bg-white border-b-xs border-gray-400 lg:flex">
        <div className="w-96">
          <div className="flex items-center py-3 mr-2 mb-0 ml-8 h-16">
            <AddLink onClick={maybeShowModal} aria-label="My Learning" className="mr-4 leading-3">
              <Icon name="chevron-left" className="inline-block w-5 h-5 text-gray-600 hover:text-black cursor-pointer" />
            </AddLink>

            <div className="mr-5 leading-3">
              <button
                type="button"
                className={`${enableHamburgerMenu && !disableSidebar ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                aria-label={`${enableHamburgerMenu && !disableSidebar ? '' : 'Disabled '}Sidebar Menu`}
                disabled={!enableHamburgerMenu || disableSidebar}
                onClick={() => togglePrimarySidebarDisplay()}
              >
                <HamburgerMenu classes="h-8 w-8 stroke-current text-gray-600 hover:text-black" />
              </button>
            </div>

            <div className="flex flex-col justify-center w-32 lg:w-64 xl:w-72">
              <h1 title={title} className="w-full max-w-lg text-base font-black truncate lg:max-w-lg xl:max-w-2xl 2xl:max-w-4xl">
                {title}
              </h1>
              <div className="flex items-center w-full max-w-lg lg:max-w-lg xl:max-w-2xl 2xl:max-w-4xl">
                <div className="overflow-hidden my-1 mx-auto mr-2 w-full h-2 text-left bg-gray-300 rounded-lg">
                  <PercentageBar color="#df057b" maxHeight="0.5rem" width={`${progress}%`} />
                </div>
                <p className="text-xs text-gray-600">{progress}%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-center items-center px-2 space-x-6">
          <PreviousNextButtons
            activity={activity}
            previous={previous}
            next={null}
            navigate={navigate}
            completedActivityId={completedActivityId}
            isLast={isLast}
            isItemLastActivity={isItemLastActivity}
            gotoCompletePage={gotoCompletePage}
            emptyChallenge={emptyChallenge}
            completeActivity={completeActivity}
            activeInterstitial={activeInterstitial}
            setActiveInterstitial={setActiveInterstitial}
            disableNext={activeInterstitial === 'upgrade'}
            setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
            isMobile={isMobile}
            setDisableSidebar={setDisableSidebar}
            disableNav={disableNav}
          />
          <div className="flex justify-center items-center md:w-48 lg:w-64 xl:w-78 2xl:w-96">
            <AddLink onClick={toggleOutline} className="flex justify-center items-center w-full">
              <h3 className="pr-3 max-w-full text-base font-bold text-center truncate">{activity?.activityTitle}</h3>
              <Icon name="chevron-down" className="w-5 h-5 text-cyb-pink-500 hover:text-cyb-pink-600" />
            </AddLink>
          </div>
          <PreviousNextButtons
            activity={activity}
            previous={null}
            next={next}
            navigate={navigate}
            completedActivityId={completedActivityId}
            isLast={isLast}
            isItemLastActivity={isItemLastActivity}
            gotoCompletePage={gotoCompletePage}
            emptyChallenge={emptyChallenge}
            completeActivity={completeActivity}
            activeInterstitial={activeInterstitial}
            setActiveInterstitial={setActiveInterstitial}
            disableNext={activeInterstitial === 'upgrade'}
            setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
            isMobile={isMobile}
            setDisableSidebar={setDisableSidebar}
            disableNav={disableNav}
            onCompletePage={onCompletePage}
          />
        </div>
        <div className="w-96 xl:w-128">{!isAssessmentType && <UserStatsHeader hideXpIfShowingUpgrade />}</div>
        <div className="mr-4 max-w-48">
          <If condition={discourseSlug}>
            <If condition={isLoading}>
              <Loading className="mr-4 w-4 h-4 border-4" />
            </If>
            <If condition={!isAssessmentType && !isLoading}>
              <Button color="gray" title="Forums" onClick={() => open(discourseSlug, { title })} icon={{ name: 'forums', position: 'left' }}>
                Forums
              </Button>
            </If>
          </If>
          <If condition={!isAssessmentType && !discourseSlug}>
            <Button color="gray" title="Forums" href={process.env.REACT_APP_FORUMS_URL} target="_blank" icon={{ name: 'forums', position: 'left' }}>
              Forums
            </Button>
          </If>
        </div>
      </div>
      <Modal
        open={showOutline}
        backdropColor="rgba(0, 0, 0, 0.15)"
        sizeClasses="md:w-[550px]"
        ariaLabel="outline modal"
        modalOverflow="scroll"
        bgBlack={false}
        paddingBottom=" "
        omitCloseX
        toggle={toggleOutline}
      >
        <div className="overflow-y-scroll relative mt-16 max-h-152 border border-gray-600 scrollbar-hide" ref={outlineContainerRef} key="outlineContainerRef">
          <TabsMenu
            tabs={desktopTabMenu}
            v2Fixed
            noTopMargin
            centerTabs
            outline={
              <Outline
                title={activity.activityTitle}
                currentActivity={activity.id}
                previous={previous}
                next={next}
                canAccessActivity={canAccessActivity}
                isIncompleteOutline={hasIncompleteItems}
                noTopMargin
                disableNav={disableNav}
              />
            }
          />
        </div>
      </Modal>
    </>
  );
}

export default DesktopPrimaryNavigation;
