import React from 'react';
import { IMMERSIVE_INTERSTITIALS, useImmersive } from '../../providers/ImmersiveProvider';
import If from '../If/If';
import UpgradeInterstitial from '../../pages/ImmersivePage/interstitials/UpgradeInterstitial';
import ActivityLimitUpgradeInterstitial from '../../pages/ImmersivePage/interstitials/ActivityLimitUpgradeInterstitial';
import ModuleUpgradeInterstitial from '../../pages/ImmersivePage/interstitials/ModuleUpgradeInterstitial';
import ThreadedUpgradeInterstitial from '../../pages/ImmersivePage/interstitials/ThreadedUpgradeInterstitial';

function ImmersiveInterstitialOverlay() {
  const { activeInterstitial } = useImmersive();

  return (
    <>
      <If condition={activeInterstitial === IMMERSIVE_INTERSTITIALS.UPGRADE}>
        <UpgradeInterstitial />
      </If>

      <If condition={activeInterstitial === IMMERSIVE_INTERSTITIALS.ACTIVITY_LIMIT_UPGRADE}>
        <ActivityLimitUpgradeInterstitial />
      </If>

      <If condition={activeInterstitial === IMMERSIVE_INTERSTITIALS.END_OF_MODULE_UPGRADE}>
        <ModuleUpgradeInterstitial />
      </If>

      <If condition={activeInterstitial === IMMERSIVE_INTERSTITIALS.THREADED_ACTIVITY_UPGRADE}>
        <ThreadedUpgradeInterstitial />
      </If>
    </>
  );
}

export default ImmersiveInterstitialOverlay;
