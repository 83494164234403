// Some utilities to help with dealing with contentful data
import { createClient } from 'contentful';
import Bugsnag from '@bugsnag/js';

class ContentfulUtil {
  // Will hold the contentful client
  client = null;

  // cache
  cache = {};

  constructor() {
    // Initialize our contentful client
    this.client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      host: process.env.REACT_APP_CONTENTFUL_HOST,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    });
  }

  /**
   * Fetch an item from contentful.  Returns a promise that will resolve to the result(s).
   *
   * @param {string} permalink The permalink of the item we are searching for
   * @param {string} type The type (in contentful) of the item we want to retrieve, defaults to contentCollection
   * @returns {Promise} The results of the query.
   */
  getByPermalink(permalink, providedType) {
    const type = providedType || 'contentCollection';

    const query = {
      limit: 1,
      include: 2,
      content_type: type,
      'fields.permalink': permalink,
    };

    return new Promise((resolve) => {
      if (this.cache[permalink] && this.cache[permalink][type]) {
        resolve(this.cache[permalink][type]);
      } else {
        this.client
          .getEntries(query)
          .then((response) => {
            if (!this.cache[permalink]) {
              this.cache[permalink] = {};
            }
            this.cache[permalink][type] = response;
            resolve(response);
          })
          .catch((err) => {
            Bugsnag.notify(err);
            return resolve({});
          });
      }
    });
  }
}

export default new ContentfulUtil();
