import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Bugsnag from '@bugsnag/js';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';

const Enrollments = inject(
  'enrollmentStore',
  'authStore'
)(
  observer(({ authStore, enrollmentStore }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = location.search ? queryString.parse(location.search) : {};

    useEffect(() => {
      authStore.fireAttributionEvent();

      // If we have provided an individual enrollment (nothing else is relevant on this page)
      if (queryParams.launch) {
        let enrollmentId = null;
        if (queryParams.launch === 'fresh') {
          // We just enrolled in something, launch it!
          enrollmentId = enrollmentStore.freshEnrollmentId;
        } else {
          // We have a particular enrollment ID, let's launch to it!
          enrollmentId = 1 * queryParams.launch;
        }

        if (enrollmentId) {
          // Go fetch the enrollment object
          if (queryParams.isActivity) {
            // If isActivity, need to hit route for enrollment activity
            navigate(`/immersive/enrollment/activity/${enrollmentId}`, { replace: true });
          } else if (queryParams.preferredCollectionItemId) {
            // If preferredCollectionItemId, need to hit route for collection item
            navigate(`/immersive/${enrollmentId}/item/${queryParams.preferredCollectionItemId}`, { replace: true });
          } else {
            // Otherwise, just hit the immersive route
            navigate(`/immersive/${enrollmentId}`, { replace: true });
          }
        } else {
          Bugsnag.notify(new Error('Enrollments missingEnrollmentId'));
          navigate('/?immersiveError=missingEnrollmentId', { replace: true });
        }
      } else {
        Bugsnag.notify(new Error('Enrollments missingParamLaunch'));
        navigate('/?immersiveError=missingParamLaunch', { replace: true });
      }
    }, []);

    // display a loader as the enrollment is preparing to launch
    return (
      <Container>
        <div className="app-page">
          <Loading message="Loading..." />
        </div>
      </Container>
    );
  })
);

export default Enrollments;
