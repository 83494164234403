import React, { createContext, useContext, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import Bugsnag from '@bugsnag/js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import moment from 'moment';
import Agents from '../agents/agents';
import PermalinkUtil from '../utils/permalinkUtil';
import { BLACKLISTED_ONBOARDING_PAGES, USERPILOT_EVENTS } from '../constants';
import { useAuth } from '../contexts/UseAuth';
import { trackSnowplowEvent } from '../utils/snowplowUtil';
import { useUserOnboarding } from './UserOnboardingProvider';

const TeamOnboardingContext = createContext();

const SP_EVENT_CATEGORY = 'TeamOnboardingStep';

export const TEAM_ONBOARDING_STEPS = {
  SELECT_ROLE: 'selectRole',
  SELECT_GOALS: 'selectGoals',
  SELECT_LEARNING_APPROACH: 'selectLearningApproach',
  FINISHED: 'finished',
};

export const TEAM_ONBOARDING_OPTIONS = {
  teamRole: {
    LEARNING_AND_DEVELOPMENT: {
      key: 'LEARNING_AND_DEVELOPMENT',
      text: 'I work in learning and development',
    },
    CYBERSECURITY: {
      key: 'CYBERSECURITY',
      text: 'I have cybersecurity responsibilities',
    },
    OTHER: {
      key: 'OTHER',
      text: 'Other',
    },
  },
  teamGoals: {
    CERTIFICATIONS: {
      key: 'CERTIFICATIONS',
      text: 'My team needs to earn or maintain certifications',
    },
    ONBOARDING: {
      key: 'ONBOARDING',
      text: 'Onboard new hires effectively and efficiently',
    },
    SKILL_ASSESSMENT: {
      key: 'SKILL_ASSESSMENT',
      text: 'Assess skill gaps in my team',
    },
    ACQUIRE_SKILLS: {
      key: 'ACQUIRE_SKILLS',
      text: 'Acquire new skills',
    },
    UPSKILL: {
      key: 'UPSKILL',
      text: 'Provide opportunities for staff to upskill/grow in their role',
    },
    CYBERSECURITY_BASICS: {
      key: 'CYBERSECURITY_BASICS',
      text: 'Improve understanding of basic cybersecurity concepts and terminology',
    },
    REDUCE_ATTRITION: {
      key: 'REDUCE_ATTRITION',
      text: 'Reduce attrition by building career development paths & building a talent pipeline',
    },
    THREAT_LANDSCAPE: {
      key: 'THREAT_LANDSCAPE',
      text: 'Keep up with the evolving threat landscape and vulnerabilities',
    },
  },
  teamLearningApproach: {
    STRUCTURED_PLAN: {
      key: 'STRUCTURED_PLAN',
      text: 'We have a clear learning plan with set goals',
    },
    PROVIDE_RESOURCES: {
      key: 'PROVIDE_RESOURCES',
      text: 'We recognize its importance and want to give our team access to learning resources',
    },
    RECOMMENDATIONS: {
      key: 'RECOMMENDATIONS',
      text: `We are open to recommendations`,
    },
  },
};

function TeamOnboardingProvider({ children, userStore }) {
  const { user } = userStore;
  /** Hooks */
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { isUserOnboardingCompleted } = useUserOnboarding();

  const [team, setTeam] = useState(null);
  const [step, setStep] = useState(TEAM_ONBOARDING_STEPS.SELECT_ROLE);
  const [isSubmitting, setIsSubmitting] = useState(false); // used when saving data
  const [isLoading, setIsLoading] = useState(false); // used when reloading user data

  const previousStep = useRef(step); // useRef to avoid state updates when values change

  const [teamRole, setTeamRole] = useState('');
  const [teamRoleOther, setTeamRoleOther] = useState('');
  const [teamGoals, setTeamGoals] = useState([]);
  const [teamLearningApproach, setTeamLearningApproach] = useState('');

  const isTeamOnboardingCompleted = useMemo(() => {
    if (!user?.id) {
      return false;
    }

    // LEGACY ONBOARDING HANDLING
    // Old Accounts do not need teams onboarding
    // The cutoff date is 9/21/2024 when the feature was released
    const accountCreatedDate = new Date(user?.registered_at);
    const cutoffDate = moment('2024-09-21').toDate();
    const isOlderThanCutoff = accountCreatedDate < cutoffDate;
    if (isOlderThanCutoff) {
      return true;
    }

    // get the teams this user can manage onboarding for
    const teamsWithOnboardingAccess = userStore?.teamsWithOnboardingAccess || {};
    let isCompleted = true;

    // check each to see if any of them are incomplete
    Object.keys(teamsWithOnboardingAccess).forEach((teamId) => {
      const teamWithOnboardingAccess = teamsWithOnboardingAccess[teamId];
      // Onboarding is considered completed if the teams onboarding object has `role` set
      if (!teamWithOnboardingAccess?.onboarding?.role) {
        isCompleted = false;
      }
    });
    return isCompleted;
  }, [user]);

  const saveTeamOnboardingData = useCallback(async () => {
    try {
      if (!team) {
        throw new Error('Unable to save onboarding data - Team not found');
      }
      setIsSubmitting(true);

      const payload = {
        role: teamRole,
        role_other: teamRoleOther,
        goals: teamGoals,
        learning_approach: teamLearningApproach,
      };

      await Agents.enterprise.putTeamOnboardingData(team.id, payload);

      // Do this after to get fresh user/team data
      await auth.refreshUser();
    } catch (error) {
      Bugsnag.notify(error, (errorEvent) => {
        const { errorMessage } = errorEvent.errors[0];
        // eslint-disable-next-line no-param-reassign
        errorEvent.context = `Team Onboarding Provider: ${errorMessage}`;
        errorEvent.addMetadata('saveTeamOnboardingData', {
          role: teamRole,
          role_other: teamRoleOther,
          goals: teamGoals,
          learning_approach: teamLearningApproach,
        });
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [auth, team, teamRole, teamRoleOther, teamGoals, teamLearningApproach]);

  const exitTeamOnboarding = useCallback(async () => {
    // Redirect to dashboard
    const teamId = team?.id;
    navigate(teamId ? `/enterprise/${teamId}/organization/members` : '/');

    setIsLoading(true);

    trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'exited', label: step });

    Userpilot.track(USERPILOT_EVENTS.FINISHED_TEAM_ONBOARDING);

    setTeam(null);
    setIsLoading(false);
  }, [auth, step]);

  const isOnboardingAllowedOnThisPage = useMemo(() => {
    // Do not show on teams invite/join pages
    const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
    const isTeamJoinPage = /^\/team-join*/.test(location.pathname);
    if (isTeamInvitePage || isTeamJoinPage) {
      return false;
    }
    // Exclude on user onboarding pages "/onboarding"
    const isUserOnboardingPage = /^\/onboarding*/.test(location.pathname);
    if (isUserOnboardingPage) {
      return false;
    }
    // Do not show on pages that are blacklisted for onboarding
    const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
    return !BLACKLISTED_ONBOARDING_PAGES.includes(cleanPathName);
  }, [location]);

  const shouldEnterTeamOnboarding = useMemo(() => {
    if (!user?.id) {
      return false;
    }
    if (!isOnboardingAllowedOnThisPage) {
      return false;
    }
    // If the user has not completed the user onboarding, do not show the team onboarding
    if (!isUserOnboardingCompleted) {
      return false;
    }
    // Onboarding is considered completed if the onboarding object has `role` set
    // get the teams this user can manage onboarding for
    const teamsWithOnboardingAccess = userStore?.teamsWithOnboardingAccess || {};
    let targetTeamId = false;
    // check each to see if any of them are incomplete
    Object.keys(teamsWithOnboardingAccess).forEach((teamId) => {
      const teamWithOnboardingAccess = teamsWithOnboardingAccess[teamId];
      if (!teamWithOnboardingAccess?.onboarding?.role) {
        targetTeamId = teamWithOnboardingAccess.id;
      }
    });
    return targetTeamId;
  }, [isTeamOnboardingCompleted, isOnboardingAllowedOnThisPage, isUserOnboardingCompleted, user]);

  // Handle tracking of onboarding steps
  useEffect(() => {
    if (step) {
      // Track the last step that was completed
      if (step !== previousStep.current) {
        trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'completed', label: previousStep.current });
      }

      // Track the step that was shown
      trackSnowplowEvent({ category: SP_EVENT_CATEGORY, action: 'shown', label: step });

      // Update the last step for next event fire
      previousStep.current = step;
    }
  }, [step]);

  // Handle entering onboarding
  useEffect(() => {
    if (shouldEnterTeamOnboarding) {
      navigate(`/onboarding/teams/${shouldEnterTeamOnboarding}`);
    }
  }, [shouldEnterTeamOnboarding, navigate]);

  const values = useMemo(
    () => ({
      team,
      step,
      teamRole,
      teamRoleOther,
      teamGoals,
      teamLearningApproach,
      isSubmitting,
      isLoading,
      shouldEnterTeamOnboarding,
      isUserOnboardingCompleted,
      setTeam,
      setStep,
      setTeamRole,
      setTeamRoleOther,
      setTeamGoals,
      setTeamLearningApproach,
      saveTeamOnboardingData,
      exitTeamOnboarding,
      user,
    }),
    [
      team,
      step,
      teamRole,
      teamRoleOther,
      teamGoals,
      teamLearningApproach,
      isSubmitting,
      isLoading,
      shouldEnterTeamOnboarding,
      isUserOnboardingCompleted,
      setTeam,
      setStep,
      setTeamRole,
      setTeamRoleOther,
      setTeamGoals,
      setTeamLearningApproach,
      saveTeamOnboardingData,
      exitTeamOnboarding,
      user,
    ]
  );
  return <TeamOnboardingContext.Provider value={values}>{children}</TeamOnboardingContext.Provider>;
}

export const useTeamOnboarding = () => useContext(TeamOnboardingContext);
export default TeamOnboardingProvider;
