import React from 'react';
import Icon from '../../../components/Icon/Icon';
import InterstitialUpgradeOrContinueButtons from './InterstitialUpgradeOrContinueButtons';
import InterstitialUpgradeButtonFooter from './InterstitialUpgradeButtonFooter';
import InterstitialContainer from './InterstitialContainer';
import { useImmersive } from '../../../providers/ImmersiveProvider';

const cipFeatures = [
  'Unlimited Courses',
  'Unlimited Certification Preparation',
  'Unlimited Virtual Labs',
  'Unlimited Assessments',
  'Access to Mentors',
  'Access to Career Coaching',
  'Access to a Private Learning Community',
  'Access to Skill Proficiency Reporting',
];
function ModuleUpgradeInterstitial() {
  const { closeInterstitial } = useImmersive();
  return (
    <InterstitialContainer>
      <div className="mt-2 mb-6 text-[48px] font-black text-black">Congratulations!</div>
      <div className="my-4 text-[20px] text-black">Nice work - you just completed this module!</div>
      <div className="mt-4 text-[24px] font-black text-black">Keep the momentum going with Cybrary Insider Pro.</div>
      <InterstitialUpgradeOrContinueButtons canContinue cybSource="ii-module-upgrade" handleClose={closeInterstitial} className="mb-6" />
      <div className="flex m-auto max-w-240">
        <div className="w-full md:w-1/2">
          <img className="m-auto max-h-72" alt="" src="https://images.ctfassets.net/kvf8rpi09wgk/7z9Z0YTrakL4JRezMBMz5E/47c4771c71901b15cbfb3ee7a61837dd/All_the_data-bro_1.svg" />
        </div>
        <div className="ml-12 w-full md:w-1/2">
          <div className="mb-2 font-bold text-left">All features in Cybrary Basic Access, plus</div>
          {cipFeatures.map((li) => (
            <div className="flex mb-1 ml-4 text-left" key={li}>
              <Icon name="check" className="mr-2 w-6 h-6 text-green-600" />
              <div>{li}</div>
            </div>
          ))}
          <InterstitialUpgradeButtonFooter cybSource="ii-module-upgrade" />
        </div>
      </div>
    </InterstitialContainer>
  );
}

export default ModuleUpgradeInterstitial;
