import React from 'react';
import If from '../If/If';
import Button from '../Button/Button';
import { IMMERSIVE_INTERSTITIALS, useImmersive } from '../../providers/ImmersiveProvider';

function ImmersiveDevButtons({ completeActivity }) {
  const { activeInterstitial, setActiveInterstitial, closeInterstitial } = useImmersive();
  return (
    <If condition={process.env.NODE_ENV === 'development'}>
      <Button color="green" onClick={() => completeActivity()} className="fixed right-10 bottom-[1.5rem] z-[500] py-1">
        Complete Activity
      </Button>
      <Button
        color={activeInterstitial === IMMERSIVE_INTERSTITIALS.UPGRADE ? 'pink' : 'gray'}
        onClick={() => setActiveInterstitial(IMMERSIVE_INTERSTITIALS.UPGRADE)}
        className="fixed right-10 bottom-[3.5rem] z-220 py-1"
      >
        Paid Lesson Upgrade
      </Button>
      <Button
        color={activeInterstitial === IMMERSIVE_INTERSTITIALS.ACTIVITY_LIMIT_UPGRADE ? 'pink' : 'gray'}
        onClick={() => setActiveInterstitial(IMMERSIVE_INTERSTITIALS.ACTIVITY_LIMIT_UPGRADE)}
        className="fixed right-10 bottom-[5.5rem] z-220 py-1"
      >
        Activity Limit
      </Button>
      <Button
        color={activeInterstitial === IMMERSIVE_INTERSTITIALS.END_OF_MODULE_UPGRADE ? 'pink' : 'gray'}
        onClick={() => setActiveInterstitial(IMMERSIVE_INTERSTITIALS.END_OF_MODULE_UPGRADE)}
        className="fixed right-10 bottom-[7.5rem] z-220 py-1"
      >
        End of Module
      </Button>
      <Button
        color={activeInterstitial === IMMERSIVE_INTERSTITIALS.THREADED_ACTIVITY_UPGRADE ? 'pink' : 'gray'}
        onClick={() => setActiveInterstitial(IMMERSIVE_INTERSTITIALS.THREADED_ACTIVITY_UPGRADE)}
        className="fixed right-10 bottom-[9.5rem] z-220 py-1"
        disabled
        title="Not in use"
      >
        Threaded Upgrade
      </Button>
      <If condition={activeInterstitial}>
        <Button color="red" onClick={closeInterstitial} className="fixed right-10 bottom-[11.5rem] z-220 py-1">
          Close Interstitial
        </Button>
      </If>
    </If>
  );
}

export default ImmersiveDevButtons;
