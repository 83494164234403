import { observable, action, set, extendObservable, makeObservable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import CryptoMD5 from 'crypto-js/md5';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import TimeUtil from '../utils/timeUtil';
import MetricsSettings from '../components/Analytics/MetricsSettings';
import DEMO_ENT_STORE_ORG from '../pages/Enterprise/Demo/DEMO_ENT_STORE_ORG.json';
import DEMO_ENT_STORE_MEMBERS from '../pages/Enterprise/Demo/DEMO_ENT_STORE_MEMBERS.json';
import DEMO_ENT_STORE_PATHS from '../pages/Enterprise/Demo/DEMO_ENT_STORE_PATHS.json';

class EnterpriseStore {
  curricula = {
    data: null,
    team: [],
    cybrary: [],
    assessments: [],
    loading: true,
    error: null,
  };

  copyMode = false;

  editMode = false;

  createMode = false;

  curriculum = null;

  curriculumLoading = true;

  curriculumError = null;

  constructor() {
    makeObservable(this, {
      curricula: observable,
      copyMode: observable,
      editMode: observable,
      createMode: observable,
      curriculum: observable,
      curriculumLoading: observable,
      curriculumError: observable,
      setCurriculaLoading: action,
      setCurriculaError: action,
      setCurricula: action,
      setEditMode: action,
      setCreateMode: action,
      setCopyMode: action,
      setCurriculumLoading: action,
      setCurriculumError: action,
      setCurriculum: action,
      setSelectBoxCount: action,
      setQueryParam: action,
      reset: action,
      loadCurricula: action,
      loadCurriculum: action,
      createCurriculum: action,
      addCurriculaImg: action,
      updateCurriculum: action,
      deleteCurriculum: action,
      setCheckboxState: action,
      resetCheckboxState: action,
      resetHeaderCheckbox: action,
      setAllSelectedRows: action,
      groupSelectedForAdd: observable,
      roleSelected: observable,
      setRoleSelected: action,
      memberTableData: observable,
      setMemberTableLoading: action,
      setMemberTableError: action,
      setDefaultMemberTable: action,
      loadTableData: action,
      assignmentData: observable,
      setDefaultAssignmentData: action,
      setAssignmentDataLoading: action,
      setAssignmentDataError: action,
      getAssignment: action,
      setAssignmentParams: action,
      submitAssignment: action,
      assignmentUsersData: observable,
      setDefaultAssignmentUsersData: action,
      setAssignmentUsersLoading: action,
      setAssignmentUsersError: action,
      getAssignmentUsersData: action,
      teamAssignmentData: observable,
      setDefaultTeamAssignmentData: action,
      setTeamAssignmentLoading: action,
      setTeamAssignmentError: action,
      getTeamAssignmentData: action,
      rerenderAssignData: action,
      teamPastAssignmentData: observable,
      setDefaultTeamPastAssignmentData: action,
      setTeamPastAssignmentLoading: action,
      setTeamPastAssignmentError: action,
      getTeamPastAssignmentData: action,
      assignmentRemoved: observable,
      deleteAssignment: action,
      userListData: observable,
      setDefaultUserListData: action,
      setUserListLoading: action,
      setUserListError: action,
      setUserListData: action,
      loadUserListData: action,
      ownerListData: observable,
      loadOwnerListData: action,
      deleteGroup: action,
      groupTableData: observable,
      setDefaultGroupTableData: action,
      setGroupTableLoading: action,
      setGroupTableError: action,
      loadGroupTableData: action,
      memberTableDataForAdd: observable,
      setDefaultMemberTableDataForAdd: action,
      setMemberTableDataForAddParam: action,
      setMemberTableLoadingForAdd: action,
      setMemberTableErrorForAdd: action,
      loadTableDataForAdd: action,
      groupProfileData: observable,
      setDefaultGroupProfileData: action,
      setGroupProfileLoading: action,
      setGroupProfileError: action,
      getGroupProfileData: action,
      editOrganization: action,
      teamInviteUrl: observable,
      setTeamInviteUrlLoading: action,
      setTeamInviteUrlError: action,
      setDefaultTeamInviteUrl: action,
      getTeamInviteUrl: action,
      invitedTeamMembers: observable,
      setDefaultInvitedTeamMembers: action,
      getInvitedTeamMembers: action,
      setTeamInviteQueryParams: action,
      joinRequests: observable,
      setDefaultJoinRequests: action,
      getJoinRequests: action,
      setJoinRequestsQueryParams: action,
      acceptJoinRequest: action,
      deleteJoinRequest: action,
      editOrgSettings: action,
      teamMemberTableData: observable,
      setDefaultTeamMemberTableData: action,
      setTeamMemberTablePage: action,
      sortTeamMemberTableData: action,
      setTeamMemberTableLoading: action,
      setTeamMemberTableError: action,
      loadTeamMemberTableData: action,
      groupsList: observable,
      setDefaultGroupList: action,
      setGroupListLoading: action,
      setGroupListError: action,
      getGroupListData: action,
      setDashboardDateFilter: action,
      setStartDate: action,
      getDashboardMetrics: action,
      getWorkroleData: action,
      setDashboardQueryParams: action,
      memberDashboardData: observable,
      setDefaultMemberDashboardData: action,
      setMemberDashboardLoading: action,
      setMemberDashboardError: action,
      getMemberDashboardHeading: action,
      getMemberDashboardGroupList: action,
      setMemberDashboardCertificatesLoading: action,
      setMemberDashboardCertificatesError: action,
      getMemberDashboardAssignmentGoals: action,
      getMemberDashboardAssignmentGoalDetails: action,
      resetMemberDashboardLearningQueryParams: action,
      getMemberDashboardLearning: action,
      assignmentDashboardData: observable,
      setDefaultAssignmentDashboardData: action,
      setAssignmentDashboardLoading: action,
      setAssignmentDashboardError: action,
      getAssignmentDashboardHeading: action,
      getAssignmentDashboardMembersTable: action,
      orgDashboardData: observable,
      setDefaultOrgDashboardData: action,
      setDashboardLeaderboardFilter: action,
      setOrgDashboardLoading: action,
      setOrgDashboardError: action,
      getGroupMeta: action,
      setFilterItem: action,
      contentDashboardData: observable,
      setDefaultContentDashboardData: action,
      setContentDashboardData: action,
      setContentDashboardLoading: action,
      setContentDashboardError: action,
      getContentDashboardHeading: action,
      getCompetencyLevels: action,
      getContentTranscript: action,
      contentPreferences: observable,
      setDefaultContentPreferences: action,
      getContentPreferences: action,
      addContentPreference: action,
      enterDemo: action,
      exitDemo: action,
      statKeys: observable,
    });
  }

  setCurriculaLoading(loading) {
    this.curricula.loading = loading;
  }

  setCurriculaError(error) {
    this.curricula.error = error;
  }

  setCurricula(view, data) {
    this.curricula[view] = data;
  }

  setEditMode(editMode) {
    this.editMode = editMode;
  }

  setCreateMode(createMode) {
    this.createMode = createMode;
  }

  setCopyMode(copyMode) {
    this.copyMode = copyMode;
  }

  setCurriculumLoading(loading) {
    this.curriculumLoading = loading;
  }

  setCurriculumError(error) {
    this.curriculumError = error;
  }

  setCurriculum(curriculum) {
    this.curriculum = curriculum;
  }

  setSelectBoxCount(countVar, count) {
    this[countVar].selectedRowsCount = count;
  }

  setQueryParam = (obj, param, value) => {
    this[obj].queryParams[param] = value;
  };

  reset() {
    // set all of our properties to the way their defaults, good for unmounting
    this.setCurriculum(null);
    this.editMode = false;
    this.createMode = false;
    this.copyMode = false;
    this.curricula = {
      data: null,
      team: [],
      cybrary: [],
      assessments: [],
      loading: true,
      error: null,
    };
  }

  curriculaSort = (item1, item2) => {
    const item1Name = item1.name.toUpperCase();
    const item2Name = item2.name.toUpperCase();

    let comparison = 0;
    if (item1Name > item2Name) {
      comparison = 1;
    } else if (item1Name < item2Name) {
      comparison = -1;
    }
    return comparison;
  };

  loadCurricula(teamId, userId, packageTypes) {
    this.setCurriculaLoading(true);
    this.setCurriculaError(null);
    const query = !packageTypes || packageTypes.indexOf('avatao') === -1 ? '?excludeAvatao=1' : '';
    return Agents.enterprise
      .getCurricula(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          const userCurricula = [];
          const teamCurricula = [];
          const cybraryCurricula = [];
          const assessmentsCurricula = []; // DEMO_ENT_STORE_PATHS.assesments || []
          if (!!response && response.length) {
            response.forEach((item) => {
              if (item.type === 'Assessment Path') {
                assessmentsCurricula.push(item);
              }

              if (!item.team_id) {
                cybraryCurricula.push(item);
              } else {
                // If path is authored by this user, add to user array
                if (!!userId && userId === item.author_id) {
                  userCurricula.push(item);
                }
                teamCurricula.push(item);
              }
            });
          }
          this.curricula.data = response;
          this.curricula.user = userCurricula;
          this.curricula.team = teamCurricula;
          this.curricula.assessments = assessmentsCurricula;
          // Sort this array alphabetically by curricula name
          cybraryCurricula.sort(this.curriculaSort);
          this.curricula.cybrary = cybraryCurricula;
          this.setCurriculaLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setCurriculaError(error);
        })
      );
  }

  loadCurriculum(teamId, id) {
    this.setCurriculumLoading(true);
    return Agents.enterprise
      .getCurriculum(teamId, id)
      .then(
        action('fetchSuccess', (response) => {
          this.setCurriculum(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setCurriculumError(error);
          this.setCurriculumLoading(false);
        })
      );
  }

  createCurriculum = (teamId, data) => {
    return Agents.enterprise
      .postCurriculum(teamId, data)
      .then(
        action('fetchSuccess', (response) => {
          return response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  addCurriculaImg = (teamId, contentDescriptionId, data) => {
    const formData = new FormData(); // Back end is expecting form data
    formData.append('image', data);
    return Agents.enterprise
      .postCurriculumImg(teamId, contentDescriptionId, formData)
      .then(
        action('fetchSuccess', (response) => {
          return response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  updateCurriculum = (teamId, id, data) => {
    return Agents.enterprise.putCurriculum(teamId, id, data);
  };

  deleteCurriculum = (teamId, id) => {
    return Agents.enterprise.deleteCurriculum(teamId, id);
  };

  curriculumAction = (url, isCybraryCurriculum, triggerConfirm, confirmContent, resetConfirmState, navigate) => {
    if (!navigate) {
      return null;
    }
    // If this is a cybrary curricula, need to show confirm message
    if (!!isCybraryCurriculum && !!triggerConfirm && !!confirmContent && !!resetConfirmState) {
      return triggerConfirm({
        content: confirmContent,
        confirmBtn: 'Continue',
        cancelBtn: 'Cancel',
        cancel: () => resetConfirmState(),
        continue: () => {
          resetConfirmState();
          navigate(url);
        },
      });
    }
    return navigate(url);
  };

  updateCurriculaVisibility = async (teamId, payload) => {
    // construct api compliant model of payload
    const settledPayload = {
      ...payload,
      curricula: payload.curricula.map((curriculum) => curriculum.id),
    };

    /**
     * Find the index of the curricula that is being updated in the team array
     * update its visibility
     */
    const curriculaToUpdate = (curriculum) => curriculum.id === payload.curricula[0].id;
    const curriculaToUpdateIndex = this.curricula.team.findIndex(curriculaToUpdate);
    try {
      /** optimistically update team paths based on payload. */
      this.curricula.team[curriculaToUpdateIndex].in_team_catalog = payload.in_team_catalog;

      // send request to update curricula visibility
      await Agents.enterprise.updateCurriculaVisibility(teamId, settledPayload);
    } catch (error) {
      /** revert optimistic updates */
      this.curricula.team[curriculaToUpdateIndex].in_team_catalog = !payload.in_team_catalog;
      throw error;
    }
  };

  /*
   **** Organization ****
   */

  setCheckboxState = (obj, idx, bool) => {
    if (bool) {
      obj.selectedRows.push(idx);
    } else {
      obj.selectedRows.splice(obj.selectedRows.indexOf(idx), 1);
    }
    set(obj.ckBoxesState, [idx], bool);
  };

  resetCheckboxState = (obj, data) => {
    this[obj].ckBoxesState = {};
    data.forEach((row, idx) => {
      this[obj].ckBoxesState[idx] = false;
    });
    this[obj].selectedRows = [];
    this[obj].headerCheckbox = false;
  };

  resetHeaderCheckbox = (obj) => {
    this[obj].headerCheckBox = false;
  };

  setAllSelectedRows(obj, checked) {
    this[obj].selectedRows = [];
    this[obj].headerCheckBox = checked;
    for (let i = 0; i < Object.keys(this[obj].ckBoxesState).length; i++) {
      this.setCheckboxState(this[obj], i, checked);
    }
  }

  // Vars used for dropdown list state management
  groupSelectedForAdd = '';

  roleSelected = '';

  setRoleSelected(role) {
    this.roleSelected = role;
  }

  addUserToGroupSuccess = false;

  memberTableData = {
    loading: true,
    error: false,
    queryParams: {},
  };

  setMemberTableLoading(loading) {
    this.memberTableData.loading = loading;
  }

  setMemberTableError(error) {
    this.memberTableData.error = error;
  }

  setDefaultMemberTable() {
    this.memberTableData = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'name',
        sortDirection: 'asc',
        searchQuery: '',
      },
      tempEndpoint: '',
    };
  }

  /**
   * Creates URL string for service call to retrieve org specific member data for table (subset of users based on pagination) - For Organization page
   */
  getMemberTableData = (cId, resetQueryParams = false) => {
    if (resetQueryParams) {
      this.memberTableData.queryParams.activePg = 1;
      this.memberTableData.queryParams.sortCol = 'name';
      this.memberTableData.queryParams.sortDirection = 'asc';
    }
    const query = `?${queryString.stringify(this.memberTableData.queryParams)}`;
    if (this.memberTableData.tempEndpoint !== query) {
      // Prevent un-needed calls to loadTableData
      this.memberTableData.tempEndpoint = query;
      this.loadTableData(cId, query);
    }
  };

  /**
   * Retrieve org specific member data for table and set data in store - For Organization page
   */
  loadTableData(teamId, query) {
    this.setMemberTableLoading(true);
    this.setMemberTableError(false);
    return Agents.enterprise
      .getMembersTable(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.memberTableData.tableData = response.tableData;
          this.memberTableData.tableHeadings = response.columns;
          this.memberTableData.recordsCount = response.totalRecords;
          this.memberTableData.pagPagesCount = Math.ceil(this.memberTableData.recordsCount / this.memberTableData.queryParams.resultsPerPage);
          this.setMemberTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setMemberTableError(error.response || error);
          this.setMemberTableLoading(false);
        })
      );
  }

  assignmentData = {
    loading: false,
    error: false,
    data: {
      name: '',
      assignee_type: 'all',
      due_date: new Date(moment().add(1, 'days')),
      assignee_id: null,
      curriculum_id: null,
      owner_ids: [],
    },
  };

  setDefaultAssignmentData() {
    this.assignmentData = {
      loading: false,
      error: false,
      data: {
        name: '',
        due_date: new Date(moment().add(1, 'days')),
        assignee_type: 'all',
        assignee_id: null,
        curriculum_id: null,
        owner_ids: [],
      },
    };
  }

  setAssignmentDataLoading(loading) {
    this.assignmentData.loading = loading;
  }

  setAssignmentDataError(error) {
    this.assignmentData.error = error;
  }

  /**
   * Retrieve an individual assignment object - For edit form
   */
  getAssignment(teamId, assignmentId) {
    this.setAssignmentDataLoading(true);
    this.setAssignmentDataError(false);
    return Agents.enterprise
      .getAssignment(teamId, assignmentId)
      .then(
        action('fetchSuccess', (response) => {
          // Set values for assignment to set form defaults
          Object.keys(this.assignmentData.data).forEach((item) => {
            this.assignmentData.data[item] = response[item];
          });
          if (!this.assignmentData.data.owner_ids) {
            this.assignmentData.data.owner_ids = [];
          }
          this.setAssignmentDataLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setAssignmentDataError(error.response || error);
          this.setAssignmentDataLoading(false);
        })
      );
  }

  setAssignmentParams = (objKey, queryData) => {
    this.assignmentData.data[objKey] = queryData;
  };

  submitAssignment = (teamId, data) => {
    const submitData = { ...data };
    if (submitData.due_date) {
      const formatDate = moment(data.due_date).format('YYYY-MM-DD');
      submitData.due_date = formatDate;
    }
    return Agents.enterprise.addAssignment(teamId, submitData);
  };

  editAssignment = (teamId, data, assignmentId) => {
    const submitData = { ...data };
    if (submitData.due_date) {
      submitData.due_date = moment(submitData.due_date).format('YYYY-MM-DD');
    }
    if (submitData.assignee_type === 'all') {
      submitData.assignee_id = null;
    }
    return Agents.enterprise.editAssignment(teamId, submitData, assignmentId);
  };

  assignmentUsersData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultAssignmentUsersData() {
    this.assignmentUsersData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setAssignmentUsersLoading(loading) {
    this.assignmentUsersData.loading = loading;
  }

  setAssignmentUsersError(error) {
    this.assignmentUsersData.error = error;
  }

  /**
   * Retrieve assignment data for all assignments within specified team, or group
   */
  getAssignmentUsersData(assignId, teamId) {
    this.setAssignmentUsersLoading(true);
    this.setAssignmentUsersError(false);
    return Agents.enterprise
      .getAssignmentUsers(assignId, teamId)
      .then(
        action('fetchSuccess', (response) => {
          this.assignmentUsersData.data = response;
          this.setAssignmentUsersLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setAssignmentUsersError(error.response || error);
          this.setAssignmentUsersLoading(false);
        })
      );
  }

  teamAssignmentData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultTeamAssignmentData() {
    this.teamAssignmentData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setTeamAssignmentLoading(loading) {
    this.teamAssignmentData.loading = loading;
  }

  setTeamAssignmentError(error) {
    this.teamAssignmentData.error = error;
  }

  /**
   * Retrieve assignment data for all assignments within specified team, or group
   */
  getTeamAssignmentData(teamId, query) {
    this.setTeamAssignmentLoading(true);
    this.setTeamAssignmentError(false);
    return Agents.enterprise
      .getTeamAssignments(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.teamAssignmentData.data = response;
          this.setTeamAssignmentLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setTeamAssignmentError(error.response || error);
          this.setTeamAssignmentLoading(false);
        })
      );
  }

  rerenderAssignData(teamId, query) {
    this.setDefaultTeamAssignmentData();
    this.setDefaultTeamPastAssignmentData();
    this.getTeamAssignmentData(teamId, query);
    this.getTeamPastAssignmentData(teamId, query);
  }

  teamPastAssignmentData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultTeamPastAssignmentData() {
    this.teamPastAssignmentData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setTeamPastAssignmentLoading(loading) {
    this.teamPastAssignmentData.loading = loading;
  }

  setTeamPastAssignmentError(error) {
    this.teamPastAssignmentData.error = error;
  }

  /**
   * Retrieve past assignment data for all assignments within specified team, or group
   */
  getTeamPastAssignmentData(teamId, query) {
    this.setTeamPastAssignmentLoading(true);
    this.setTeamPastAssignmentError(false);
    return Agents.enterprise
      .getTeamPastAssignments(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.teamPastAssignmentData.data = response;
          this.setTeamPastAssignmentLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setTeamPastAssignmentError(error.response || error);
          this.setTeamPastAssignmentLoading(false);
        })
      );
  }

  assignmentRemoved = false;

  deleteAssignment = (teamId, assignId) => {
    return Agents.enterprise.deleteAssignment(teamId, assignId);
  };

  /**
   * Send users ID's and group ID to endpoint to assign users to provided group
   */
  addUsersToGroup(gId, users, obj, role) {
    const userIds = [];
    if (typeof users !== 'object') {
      // If single user ID is passed in
      userIds.push(users);
    } else if (obj) {
      users.forEach((row) => {
        userIds.push(this[obj].tableData[row][0].value);
        return null;
      });
    } else {
      userIds.push(...users);
    }
    const postData = { users: userIds };
    if (role) {
      postData.role = role;
    }

    return Agents.enterprise.addTeamMember(gId, postData);
  }

  userListData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultUserListData() {
    this.userListData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setUserListLoading(loading) {
    this.userListData.loading = loading;
  }

  setUserListError(error) {
    this.userListData.error = error;
  }

  setUserListData(data) {
    this.userListData.data = data;
  }

  /**
   * Creates URL string for service call to retrieve team specific member data for listing in select list (all users) - For Organization page
   */
  getUserListData = (orgId, params) => {
    const query = params ? `?${queryString.stringify(params)}` : null;
    return this.loadUserListData(orgId, query);
  };

  /**
   * Retrieve team specific member data for listing and set data in store - For Organization page
   */
  loadUserListData(orgId, query) {
    this.setUserListLoading(true);
    this.setUserListError(false);
    return Agents.enterprise
      .getMembersList(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.setUserListData(response.data);
          this.setUserListLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setUserListError(error.response || error);
          this.setUserListLoading(false);
        })
      );
  }

  ownerListData = {
    loading: true,
    error: false,
    data: null,
  };

  loadOwnerListData(orgId, user) {
    this.ownerListData.loading = true;
    this.ownerListData.error = false;
    const query = '?roles[]=org-owner&roles[]=team-admin';
    return Agents.enterprise
      .getMembersList(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.ownerListData.data = response.data;
          this.addUserToOwnerList(user);
          this.ownerListData.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.ownerListData.error = error.response || error;
          this.ownerListData.loading = false;
        })
      );
  }

  addUserToOwnerList = (user) => {
    let hasUser = false;
    this.ownerListData.data.forEach((owner) => {
      if (owner.value === user.id) {
        hasUser = true;
      }
    });
    if (!hasUser) {
      this.ownerListData.data.push({ text: user.real_name || user.name, value: user.id });
    }
    this.setAssignmentParams('owner_ids', [user.id]);
  };

  /**
   * Send users ID and group ID to endpoint to create new group and assign user as lead
   */
  addNewGroup = (cId, data) => {
    return Agents.enterprise.addGroup(cId, data);
  };

  /**
   * Edit group info
   */
  editGroup = (team, group, data) => {
    return Agents.enterprise.editGroup(team, group, data);
  };

  deleteGroup = (teamId, groupId) => {
    const groupIdDelete = {
      groups: !Array.isArray(groupId) ? [groupId] : groupId,
    };
    return Agents.enterprise.deleteGroups(teamId, groupIdDelete);
  };

  groupTableData = {
    loading: true,
    error: false,
    queryParams: {},
  };

  setDefaultGroupTableData() {
    this.groupTableData = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'name',
        sortDirection: 'asc',
      },
      tempEndpoint: '',
    };
  }

  setGroupTableLoading(loading) {
    this.groupTableData.loading = loading;
  }

  setGroupTableError(error) {
    this.groupTableData.error = error;
  }

  /**
   * Creates URL string for service call to retrieve group data for table (Subset of groups based on pagination) - For Organization page
   */
  getGroupTableData = (cId, resetQueryParams = false) => {
    if (resetQueryParams) {
      this.groupTableData.queryParams.activePg = 1;
      this.groupTableData.queryParams.sortCol = 'name';
      this.groupTableData.queryParams.sortDirection = 'asc';
    }
    const query = `?${queryString.stringify(this.groupTableData.queryParams)}`;
    if (this.groupTableData.tempEndpoint !== query) {
      // Prevent un-needed calls to loadTableData
      this.groupTableData.tempEndpoint = query;
      this.loadGroupTableData(cId, query);
    }
  };

  /**
   * Retrieve group data for table and set data in store - For Organization page
   */
  loadGroupTableData(cId, query) {
    this.setGroupTableLoading(true);
    this.setGroupTableError(false);
    return Agents.enterprise
      .getGroupsTable(cId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.groupTableData.tableData = response.tableData;
          this.groupTableData.tableHeadings = response.columns;
          this.groupTableData.recordsCount = response.totalRecords;
          this.groupTableData.pagPagesCount = Math.ceil(this.groupTableData.recordsCount / this.groupTableData.queryParams.resultsPerPage);
          this.setGroupTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setGroupTableError(error.response || error);
          this.setGroupTableLoading(false);
        })
      );
  }

  /**
   * Change User Roles
   */
  changeUserRoles(users, role, team, obj) {
    const userIds = [];
    if (typeof users !== 'object') {
      // If single user ID is passed in
      userIds.push(users);
    } else {
      users.forEach((row) => {
        userIds.push(this[obj].tableData[row][0].value);
      });
    }

    const data = {
      users: userIds,
      role,
    };

    return Agents.enterprise.setTeamMemberRoles(team, data);
  }

  /**
   * Send users ID's and group ID to endpoint to remove from provided group
   */
  removeMembers(teamId, users, obj) {
    const userIds = [];
    if (typeof users !== 'object') {
      userIds.push(users);
    } else {
      users.forEach((row) => {
        userIds.push(this[obj].tableData[row][0].value);
      });
    }

    return Agents.enterprise.removeTeamMembers(teamId, {
      users: userIds,
    });
  }

  memberTableDataForAdd = {
    loading: true,
    error: false,
    queryParams: {},
    selectedRowsCount: 0,
    ckBoxesState: {},
    selectedRows: [],
    headerCheckBox: false,
  };

  setDefaultMemberTableDataForAdd() {
    this.memberTableDataForAdd = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        searchQuery: '',
      },
      tempEndpoint: '',
      selectedRowsCount: 0,
      ckBoxesState: {},
      selectedRows: [],
      headerCheckBox: false,
    };
  }

  setMemberTableDataForAddParam(name, value) {
    this.memberTableDataForAdd.queryParams[name] = value;
  }

  setMemberTableLoadingForAdd(loading) {
    this.memberTableDataForAdd.loading = loading;
  }

  setMemberTableErrorForAdd(error) {
    this.memberTableDataForAdd.error = error;
  }

  /**
   * Creates URL string for service call to retrieve org specific member data for table (subset of users based on pagination) - For Organization page
   */
  getMemberTableDataForAdd = (gId, resetQueryParams, callback) => {
    if (resetQueryParams) {
      this.memberTableDataForAdd.queryParams.activePg = 1;
    }
    const query = `?${queryString.stringify(this.memberTableDataForAdd.queryParams)}`;
    if (this.memberTableDataForAdd.tempEndpoint !== query) {
      // Prevent un-needed calls to loadTableData
      this.memberTableDataForAdd.tempEndpoint = query;
      this.loadTableDataForAdd(gId, query, callback);
    }
  };

  /**
   * Retrieve org specific member data for table and set data in store - For Organization page
   */
  loadTableDataForAdd(gId, query, callback) {
    this.setMemberTableLoadingForAdd(true);
    this.setMemberTableErrorForAdd(false);
    return Agents.enterprise
      .getMembersNotInTeamTable(gId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.memberTableDataForAdd.tableData = response.tableData;
          this.memberTableDataForAdd.tableHeadings = response.columns;
          this.memberTableDataForAdd.recordsCount = response.totalRecords;
          this.memberTableDataForAdd.pagPagesCount = Math.ceil(this.memberTableDataForAdd.recordsCount / this.memberTableDataForAdd.queryParams.resultsPerPage);
          if (callback) {
            callback(response.tableData);
          }
          this.resetCheckboxState('memberTableDataForAdd', response.tableData);
          this.setMemberTableLoadingForAdd(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setMemberTableErrorForAdd(error.response || error);
          this.setMemberTableLoadingForAdd(false);
        })
      );
  }

  groupProfileData = {
    loading: true,
    error: false,
    profileData: null,
  };

  setDefaultGroupProfileData() {
    this.groupProfileData = {
      loading: true,
      error: false,
      profileData: null,
    };
  }

  setGroupProfileLoading(loading) {
    this.groupProfileData.loading = loading;
  }

  setGroupProfileError(error) {
    this.groupProfileData.error = error;
  }

  /**
   * Retrieve group profile data - For Organization page
   */
  getGroupProfileData(gId) {
    this.setGroupProfileLoading(true);
    this.setGroupProfileError(false);
    return Agents.enterprise
      .getTeamMeta(gId)
      .then(
        action('fetchSuccess', (response) => {
          this.groupProfileData.profileData = response;
          this.setGroupProfileLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setGroupProfileError(error.response || error);
          this.setGroupProfileLoading(false);
        })
      );
  }

  editOrganization = (data, team) => {
    return Agents.enterprise.editOrgSettings(team, data);
  };

  teamInviteUrl = {
    loading: true,
    error: false,
    url: '',
    orgId: null,
  };

  setTeamInviteUrlLoading(loading) {
    this.teamInviteUrl.loading = loading;
  }

  setTeamInviteUrlError(error) {
    this.teamInviteUrl.error = error;
  }

  setDefaultTeamInviteUrl() {
    this.teamInviteUrl = {
      loading: true,
      error: false,
      url: '',
      orgId: null,
    };
  }

  /**
   * Retrieve org specific member data for table and set data in store - For Organization page
   */
  getTeamInviteUrl = (orgId) => {
    if (this.teamInviteUrl.orgId !== orgId) {
      this.teamInviteUrl.orgId = orgId;
      this.setTeamInviteUrlLoading(true);
      this.setTeamInviteUrlError(false);
      return Agents.enterprise
        .getInviteUrl(orgId)
        .then(
          action('fetchSuccess', (response) => {
            this.teamInviteUrl.url = /^http/.test(response) ? response : process.env.REACT_APP_V2_SITE_URL + response;
            this.setTeamInviteUrlLoading(false);
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            this.setTeamInviteUrlError(error.response || error);
            this.setTeamInviteUrlLoading(false);
          })
        );
    }
    return null;
  };

  /**
   * Retrieve email address from form invite modal
   */
  getTeamInviteUrlByEmail = (orgId, data) => {
    return Agents.enterprise.sendTeamInviteByEmail(orgId, { invites: data });
  };

  invitedTeamMembers = {
    loading: true,
    error: false,
    data: null,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
    },
  };

  setDefaultInvitedTeamMembers() {
    this.invitedTeamMembers = {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
      },
    };
  }

  setTeamInviteQueryParams = (param, value) => {
    this.invitedTeamMembers.queryParams[param] = value;
  };

  /**
   * Retrieve org specific member data for table and set data in store - For Organization page
   */
  getInvitedTeamMembers = (orgId) => {
    this.invitedTeamMembers.loading = true;
    this.invitedTeamMembers.error = false;
    const query = `?format=table&${queryString.stringify(this.invitedTeamMembers.queryParams)}`;
    return Agents.enterprise
      .getPendingInvites(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.invitedTeamMembers.loading = false;
          this.invitedTeamMembers.pageCount = response.totalRecords ? Math.ceil(response.totalRecords / this.invitedTeamMembers.queryParams.resultsPerPage) : 0;
          this.invitedTeamMembers.data = response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.invitedTeamMembers.loading = false;
          this.invitedTeamMembers.error = error.response || error;
        })
      );
  };

  resendTeamInvitation = (orgId, id) => {
    this.invitedTeamMembers.loading = true;
    this.invitedTeamMembers.error = false;
    return Agents.enterprise
      .resendInvite(orgId, id)
      .then(
        action('resendSuccess', () => {
          this.invitedTeamMembers.loading = false;
        })
      )
      .catch(
        action('resendError', (error) => {
          this.invitedTeamMembers.loading = false;
          this.invitedTeamMembers.error = error.response || error;
          throw error;
        })
      );
  };

  deleteTeamInvitation = (orgId, id) => {
    this.invitedTeamMembers.loading = true;
    this.invitedTeamMembers.error = false;
    return Agents.enterprise
      .deleteInvite(orgId, id)
      .then(
        action('deleteSuccess', () => {
          this.invitedTeamMembers.loading = false;
        })
      )
      .catch(
        action('deleteError', (error) => {
          this.invitedTeamMembers.loading = false;
          this.invitedTeamMembers.error = error.response || error;
          throw error;
        })
      );
  };

  /** Team License Join Requests */
  joinRequests = {
    loading: true,
    error: false,
    data: null,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
    },
  };

  setDefaultJoinRequests() {
    this.joinRequests = {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
      },
    };
  }

  acceptJoinRequest = (teamId, teamJoinRequestId) => {
    this.joinRequests.loading = true;
    this.joinRequests.error = false;
    return Agents.teamsLicenseCheck
      .respondToTeamJoinRequest(teamId, teamJoinRequestId, { approved: 1 })
      .then(
        action('acceptSuccess', () => {
          this.joinRequests.loading = false;
        })
      )
      .catch(
        action('acceptError', (error) => {
          this.joinRequests.loading = false;
          this.joinRequests.error = error.response || error;
          throw error;
        })
      );
  };

  deleteJoinRequest = (teamId, teamJoinRequestId) => {
    this.joinRequests.loading = true;
    this.joinRequests.error = false;
    return Agents.teamsLicenseCheck
      .respondToTeamJoinRequest(teamId, teamJoinRequestId, { approved: 0 })
      .then(
        action('deleteSuccess', () => {
          this.joinRequests.loading = false;
        })
      )
      .catch(
        action('deleteError', (error) => {
          this.joinRequests.loading = false;
          this.joinRequests.error = error.response || error;
          throw error;
        })
      );
  };

  setJoinRequestsQueryParams = (param, value) => {
    this.joinRequests.queryParams[param] = value;
  };

  /**
   * Retrieve org specific member data for table and set data in store - For Organization page
   */
  getJoinRequests = (orgId) => {
    this.joinRequests.loading = true;
    this.joinRequests.error = false;
    const query = `?format=table&${queryString.stringify(this.joinRequests.queryParams)}`;
    return Agents.teamsLicenseCheck
      .getTeamJoinRequests(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.joinRequests.loading = false;
          this.joinRequests.pageCount = response.totalRecords ? Math.ceil(response.totalRecords / this.joinRequests.queryParams.resultsPerPage) : 0;
          this.joinRequests.data = response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.joinRequests.loading = false;
          this.joinRequests.error = error.response || error;
        })
      );
  };

  /* Org Owner functionality */

  /**
   * Send users ID's and role ID to endpoint to assign users to provided group
   */
  manageLicenses(team, users, status) {
    const userIds = [];
    if (typeof users !== 'object') {
      // If single user ID is passed in
      userIds.push(users);
    } else {
      users.forEach((row) => {
        userIds.push(this.teamMemberTableData.tableData[row][0].value);
      });
    }

    const data = {
      users: userIds,
      grant: status,
    };

    return Agents.enterprise.manageLicenses(team, data);
  }

  /**
   * Request a license from existing org owner, or invite manager to team if not an owner
   */
  requestLicense(orgId, ownerId, managerEmail) {
    const ownerData = {};
    // Different post data based on whether requesting from existing owner, or needing to invite manager first
    if (ownerId) {
      ownerData.to_user_id = ownerId;
    } else if (!ownerId && managerEmail) {
      ownerData.manager_email = managerEmail;
    }
    return Agents.enterprise.requestLicense(orgId, ownerData);
  }

  /**
   * Request an add on license from existing org owner, or invite manager to team if not an owner
   */
  requestAddOnLicense(orgId, ownerId, managerEmail) {
    const ownerData = {};
    // Different post data based on whether requesting from existing owner, or needing to invite manager first
    if (ownerId) {
      ownerData.to_user_id = ownerId;
    } else if (!ownerId && managerEmail) {
      ownerData.manager_email = managerEmail;
    }
    return Agents.enterprise.requestAddOnLicense(orgId, ownerData);
  }

  /**
   * Edit organization settings
   */
  editOrgSettings = (teamId, settings) => {
    return Agents.enterprise.editOrgSettings(teamId, settings);
  };

  teamMemberTableData = {
    loading: true,
    error: false,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
      sortCol: 'join_date',
      sortDirection: 'desc',
      searchQuery: '',
      groupId: '',
      role: '',
      license: '',
    },
    tempEndpoint: '',
    ckBoxesState: {},
    selectedRows: [],
    selectedRole: null,
    headerCheckBox: false,
    totalRecords: '',
  };

  setDefaultTeamMemberTableData() {
    this.teamMemberTableData = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'join_date',
        sortDirection: 'desc',
        searchQuery: '',
        groupId: '',
        role: '',
        license: '',
      },
      tempEndpoint: '',
      ckBoxesState: {},
      selectedRows: [],
      selectedRole: null,
      headerCheckBox: false,
      totalRecords: '',
    };
  }

  setTeamMemberTableLoading(loading) {
    this.teamMemberTableData.loading = loading;
  }

  setTeamMemberTableError(error) {
    this.teamMemberTableData.error = error;
  }

  /**
   * Gets a table with the current team members on the team
   */
  getTeamMemberTableData = (cId, resetQueryParams = false, reloadAfterAction = false) => {
    if (resetQueryParams) {
      this.teamMemberTableData.queryParams.activePg = 1;
    }
    const query = `?${queryString.stringify(this.teamMemberTableData.queryParams)}`;
    if (this.teamMemberTableData.tempEndpoint !== query || reloadAfterAction) {
      // Prevent un-needed calls to loadTableData
      this.teamMemberTableData.tempEndpoint = query;
      this.loadTeamMemberTableData(cId, query);
    }
  };

  loadTeamMemberTableData(teamId, query) {
    this.setTeamMemberTableLoading(true);
    this.setTeamMemberTableError(false);
    return Agents.enterprise
      .getMembersTable(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.teamMemberTableData.tableData = response.tableData;
          this.teamMemberTableData.tableHeadings = response.columns;
          this.teamMemberTableData.totalRecords = response.totalRecords;
          this.teamMemberTableData.pagPagesCount = Math.ceil(this.teamMemberTableData.totalRecords / this.teamMemberTableData.queryParams.resultsPerPage);
          this.resetCheckboxState('teamMemberTableData', response.tableData); // Sets the initial state for the checkboxes used in table
          this.resetHeaderCheckbox('teamMemberTableData');
          this.setTeamMemberTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setTeamMemberTableError(error.response || error);
          this.setTeamMemberTableLoading(false);
        })
      );
  }

  sortTeamMemberTableData = (heading, orgId) => {
    if (heading) {
      if (heading === this.teamMemberTableData.queryParams.sortCol) {
        this.teamMemberTableData.queryParams.sortDirection = this.teamMemberTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
      } else {
        this.teamMemberTableData.queryParams.sortCol = heading;
        this.teamMemberTableData.queryParams.sortDirection = 'desc';
      }
      this.teamMemberTableData.queryParams.activePg = 1;
      this.getTeamMemberTableData(orgId, false, false);
    }
  };

  setTeamMemberTablePage = (page, orgId) => {
    this.teamMemberTableData.queryParams.activePg = page;
    this.getTeamMemberTableData(orgId, false, false);
  };

  /* List of groups within team */
  groupsList = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultGroupList() {
    this.groupsList = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setGroupListLoading(loading) {
    this.groupsList.loading = loading;
  }

  setGroupListError(error) {
    this.groupsList.error = error;
  }

  getGroupListData(orgId) {
    this.setGroupListLoading(true);
    this.setGroupListError(false);
    return Agents.enterprise
      .getGroupsList(orgId)
      .then(
        action('fetchSuccess', (response) => {
          this.groupsList.data = response.data;
          this.setGroupListLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setGroupListError(error.response || error);
          this.setGroupListLoading(false);
        })
      );
  }

  postTeamLogo = (teamId, data, isSalesDashboard) => {
    const formData = new FormData(); // Back end is expecting form data
    formData.append('image', data.image);
    if (isSalesDashboard) {
      return Agents.admin.uploadTeamLogo(teamId, formData);
    }
    return Agents.enterprise.uploadTeamLogo(teamId, formData);
  };

  setDashboardDateFilter = (obj, item, val) => {
    if (item === 'range') {
      this[obj].filters.range = val;
    } else if (this[obj].filters[item] !== moment(val)) {
      this[obj].filters[item] = moment(val);
    }
  };

  getDateFilterParams = (startDate, endDate) => {
    return {
      startDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
      endDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
    };
  };

  setStartDate = (obj, date) => {
    this[obj].filters.startDate = date;
  };

  // *** Dashboards ***

  getIntervalParam = (interval, statObj, dateFilters) => {
    let intervalVal = interval;
    if (interval === 'time' && !!dateFilters) {
      intervalVal = TimeUtil.getDateRangeInterval(dateFilters.startDate, dateFilters.endDate);
    }
    this[statObj].filters.interval = intervalVal;
    return intervalVal;
  };

  setStats = (data, obj, statConfig, dateFilters) => {
    const statKey = statConfig.key;
    if (this[obj].stats[statKey]) {
      this[obj].stats[statKey].data = data;
      if (statKey === 'learningActivityInteractions') {
        const momentStart = moment(dateFilters.startDate);
        const momentEnd = moment(dateFilters.endDate);
        const averagePerDay = data['Learning Activity Interactions'].count / momentEnd.diff(momentStart, 'days');
        this[obj].stats[statKey].data.averagePerDay = {
          count: averagePerDay === Infinity ? 0 : averagePerDay,
        };
      }
      this[obj].stats[statKey].loading = false;
      this[obj].stats[statKey].tableView = statConfig.tableView;
    }
  };

  getGoalsStatTotal = (data) => {
    if (!data || !data.goalsByStatus) {
      return null;
    }
    return data.goalsByStatus.reduce((sum, item) => sum + item.count, 0);
  };

  getTransformedDataSet = (ret, statConfig, setIdx, data, dataIdx) => {
    const graphData = { ...ret };
    switch (statConfig.key) {
      case 'learningActivityInteractions':
        graphData['Learning Activity Interactions'] = { count: data.total };
        break;
      case 'activityCompletions':
        graphData['Learning Activity Completions'] = { count: data.total };
        break;
      case 'enrolledActivities':
        graphData['Unique Users'] = { count: data.unique };
        graphData['Total Enrollments'] = { count: data.total };
        break;
      case 'curriculaActivity':
        graphData[data.path] = {
          count: data.learning_hours || 0,
        };
        break;
      case 'proficientActivities':
        graphData['Proficient Attempts'] = { count: data.proficient };
        break;
      case 'contentScoreDistribution':
        if (dataIdx === 0) {
          graphData.range = [];
        }
        graphData.range.push({ count: data.count || 0, score_range: dataIdx, label: data.score_range });
        break;
      case 'learningHoursAssignment':
        if (dataIdx === 0) {
          graphData.assigned = [];
        }
        graphData.assigned.push({ count: data.total || 0, period: data.period });
        break;
      case 'learningHoursMixed':
        if (dataIdx === 0) {
          graphData.assigned = [];
          graphData['Additional Learning Hours'] = [];
          graphData['Total Learning Hours'] = [];
        }
        graphData['Total Learning Hours'].push({ count: data.total || 0, period: data.period });
        graphData.assigned.push({ count: data.assigned || 0, period: data.period });
        graphData['Additional Learning Hours'].push({ count: data.unassigned || 0, period: data.period });
        break;
      case 'contentAttempts':
        if (dataIdx === 0) {
          graphData['Users Attempted'] = [];
        }
        graphData['Users Attempted'].push({ count: data.total || 0, period: data.period });
        break;
      case 'learningHoursSummary':
        if (setIdx === 0) {
          graphData['Total Learning Hours'] = { count: data.total };
        } else {
          graphData.ceu = { count: data.total };
        }
        break;
      default:
        Object.keys(data).forEach((item) => {
          graphData[item] = {
            count: data[item] || 0,
          };
        });
        break;
    }
    return graphData;
  };

  // Transform new data format to match format expected in UI components
  transformGraphData = (graphData, statConfig) => {
    if (!graphData) {
      return null;
    }
    let transformedData = {};
    graphData.forEach((dataSet, setIdx) => {
      dataSet.forEach((data, dataIdx) => {
        transformedData = this.getTransformedDataSet(transformedData, statConfig, setIdx, data, dataIdx);
      });
    });
    return transformedData;
  };

  getDashboardMetrics(obj, orgId, memberAssignmentId, statConfig, contentId, tabularView, tabularViewChange) {
    const showStatInTable = tabularView && statConfig.tableView;
    // If we're getting data due to user changing graph view, but this stat doesn't show diff view, don't do anything
    if (tabularViewChange && !statConfig.tableView) {
      return null;
    }
    // If we're attempting to reload member activity data when it's already loaded, don't do anything
    if (statConfig.key === 'memberActivity' && this[obj]?.stats?.[statConfig.key]?.data) {
      return null;
    }
    const statKey = statConfig.key;
    const filterParams = statConfig.filters || {};
    const { reports } = statConfig;
    const dateFilters = this.getDateFilterParams(this[obj].filters.startDate, this[obj].filters.endDate);
    const queryParams = {
      ...dateFilters,
      interval: this.getIntervalParam(statConfig.interval, obj, dateFilters),
      ...filterParams,
    };
    // If content ID is passed in, add that as a filter as well
    if (contentId) {
      queryParams.contentDescriptionId = contentId;
    }

    // Depending on data obj, use correct scope param
    if (memberAssignmentId) {
      queryParams[obj === 'assignmentDashboardData' ? 'assignmentId' : 'userId'] = memberAssignmentId;
    }

    // Either create a new stat obj, or set existing to loading while fetching data
    if (!this[obj].stats[statKey]) {
      extendObservable(this[obj].stats, {
        [statKey]: {
          data: null,
          loading: true,
          error: false,
        },
      });
    } else {
      this[obj].stats[statKey].loading = true;
      this[obj].stats[statKey].error = false;
    }

    // Save off the current URL hash to compare to URL hash when AJAX call resolves. Prevents a render of a previous dashboard's data when user quickly switches from one to another (from org into a group dashboard)
    const urlHash = CryptoMD5(window.location.href).toString();

    if (showStatInTable) {
      queryParams.format = 'table';
    }

    const query = `?${queryString.stringify(queryParams)}`;
    // Set up promises for all the separate report calls needed for the stat section
    const promises = reports.map((report) => {
      return Agents.reports.getReportData(orgId, report, query);
    });

    return Promise.all(promises)
      .then(
        action('fetchSuccess', (response) => {
          // If still at the same URL when request was made
          if (urlHash === CryptoMD5(window.location.href).toString()) {
            const data = !showStatInTable ? this.transformGraphData(response, statConfig) : response[0];
            this.setStats(data, obj, statConfig, dateFilters);
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          if (this[obj].stats[statKey]) {
            this[obj].stats[statKey].error = error.response || error;
            this[obj].stats[statKey].loading = false;
          }
        })
      );
  }

  getDashboardLeaderboards(obj, orgId, leaderboard, leaderboardConfig, callback) {
    const metricConfig = MetricsSettings.leaderboards[leaderboard];
    const dateFilters = this.getDateFilterParams(this[obj].filters.startDate, this[obj].filters.endDate);
    if (!this[obj].leaderboards[leaderboard]) {
      extendObservable(this[obj].leaderboards, {
        [leaderboard]: {
          data: null,
          loading: true,
          error: false,
          queryParams: leaderboardConfig && leaderboardConfig.queryParams ? leaderboardConfig.queryParams : {},
          config: metricConfig ?? leaderboardConfig,
        },
      });
    } else {
      this[obj].leaderboards[leaderboard].loading = true;
      this[obj].leaderboards[leaderboard].error = false;
    }

    const query = `?${queryString.stringify(dateFilters)}&${queryString.stringify(this[obj].leaderboards[leaderboard].queryParams, { arrayFormat: 'index' })}&format=table`;

    // Save off the current URL hash to compare to URL hash when AJAX call resolves. Prevents a render of a previous dashboard's data when user quickly switches from one to another (from org into a group dashboard)
    const urlHash = CryptoMD5(window.location.href).toString();
    return Agents.enterprise
      .getLeaderboard(orgId, metricConfig?.report ?? leaderboard, query, leaderboardConfig?.isMember)
      .then(
        action('fetchSuccess', (response) => {
          if (this[obj].leaderboards[leaderboard] && urlHash === CryptoMD5(window.location.href).toString()) {
            this[obj].leaderboards[leaderboard].data = response;
            this[obj].leaderboards[leaderboard].loading = false;
          }
          if (callback) {
            callback();
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          if (this[obj].leaderboards[leaderboard]) {
            this[obj].leaderboards[leaderboard].error = error.response || error;
            this[obj].leaderboards[leaderboard].loading = false;
          }
        })
      );
  }

  getWorkroleData = (orgId, obj, enrollmentId, scoreId) => {
    this[obj].workroleData = {
      loading: true,
      error: false,
      data: null,
    };
    let getMethod = scoreId ? () => Agents.enrollments.getScoreById(scoreId) : null;
    if (!getMethod && !!enrollmentId) {
      getMethod = () => Agents.enterprise.getWorkroleData(orgId, enrollmentId);
    }
    if (!getMethod) {
      return null;
    }

    return getMethod()
      .then(
        action('fetchSuccess', (response) => {
          this[obj].workroleData.data = response;
          this[obj].workroleData.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this[obj].workroleData.error = error.response || error;
          this[obj].workroleData.loading = false;
        })
      );
  };

  setDashboardQueryParams = (obj, section, value) => {
    this[obj][section].queryParams = value;
  };

  /* Stat report name consts */
  statKeys = {
    learningHours: 'learning-hours-interval',
    enrolledActivities: 'activities-enrolled-interval',
    ceus: 'ceus-interval',
    activityTypes: 'activity-types-interval',
    goals: 'goal-statuses',
    completeActivities: 'activities-completed-interval',
    averageScore: 'activity-avg-score-interval',
    activityGrades: 'activity-grades-interval',
    scoreRange: 'activity-score-range',
    assignments: 'assignment-statuses',
    members: 'member-statuses',
  };

  // *** Member Dashboard ***

  memberDashboardData = {
    heading: {
      loading: true,
      error: false,
      data: null,
    },
    groups: {
      loading: true,
      error: false,
      data: null,
    },
    groupList: {
      loading: true,
      error: false,
      data: null,
    },
    stats: {},
    certificates: {
      data: {
        Course: [],
        MicroCourse: [],
      },
      loading: true,
      error: false,
    },
    assignments: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    goals: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    learning: {
      loading: true,
      error: false,
      data: {},
      queryParams: {
        activePg: 1,
        recordsPerPage: 20,
        sortCol: 'content_description_title',
        sortDirection: 'asc',
        status: 'all',
      },
    },
    workroleData: {
      loading: true,
      error: false,
      data: null,
    },
    filters: {
      range: 'allTime',
      startDate: moment('2015-01-01'),
      endDate: moment(),
      interval: null,
    },
    config: {
      stats: {
        stat_cards: [
          { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
          { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
          { key: 'learningActivities', reports: [this.statKeys.activityTypes], tableView: true },
          { key: 'goals', reports: [this.statKeys.goals], tableView: true },
        ],
        mixed_stat: [{ key: 'learningHoursMixed', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
      },
    },
  };

  setDefaultMemberDashboardData() {
    this.memberDashboardData = {
      heading: {
        loading: true,
        error: false,
        data: null,
      },
      groups: {
        loading: true,
        error: false,
        data: null,
      },
      groupList: {
        loading: true,
        error: false,
        data: null,
      },
      stats: {},
      certificates: {
        data: {
          Course: [],
          MicroCourse: [],
        },
        loading: true,
        error: false,
      },
      assignments: {
        loading: true,
        error: false,
        data: null,
        queryParams: {
          status: 'all',
        },
      },
      goals: {
        loading: true,
        error: false,
        data: null,
        queryParams: {
          status: 'all',
        },
      },
      learning: {
        loading: true,
        error: false,
        data: {},
        queryParams: {
          activePg: 1,
          recordsPerPage: 20,
          sortCol: 'content_description_title',
          sortDirection: 'asc',
          status: 'all',
        },
      },
      workroleData: {
        loading: true,
        error: false,
        data: null,
      },
      filters: {
        range: 'allTime',
        startDate: moment('2015-01-01'),
        endDate: moment(),
        interval: null,
      },
      config: {
        stats: {
          stat_cards: [
            { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
            { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
            { key: 'learningActivities', reports: [this.statKeys.activityTypes], tableView: true },
            { key: 'goals', reports: [this.statKeys.goals], tableView: true },
          ],
          mixed_stat: [{ key: 'learningHoursMixed', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
        },
      },
    };
  }

  setMemberDashboardLoading(obj, loading) {
    this.memberDashboardData[obj].loading = loading;
  }

  setMemberDashboardError(obj, error) {
    this.memberDashboardData[obj].error = error;
  }

  /**
   * Retrieve member dashboard heading data - For Organization page
   */
  getMemberDashboardHeading(orgId, userId) {
    this.setMemberDashboardLoading('heading', true);
    this.setMemberDashboardError('heading', false);
    return Agents.enterprise
      .getMemberProfile(orgId, userId)
      .then(
        action('fetchSuccess', (response) => {
          this.memberDashboardData.heading.data = response;
          this.setMemberDashboardLoading('heading', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.memberDashboardData.heading.error = error.response || error;
          this.setMemberDashboardLoading('heading', false);
        })
      );
  }

  getMemberDashboardGroupList(orgId, userId) {
    this.setMemberDashboardLoading('groupList', true);
    this.setMemberDashboardError('groupList', false);
    return Agents.enterprise
      .getGroupsExcludingMemberList(orgId, userId)
      .then(
        action('fetchSuccess', (response) => {
          this.memberDashboardData.groupList.data = response;
          this.setMemberDashboardLoading('groupList', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.memberDashboardData.groupList.error = error.response || error;
          this.setMemberDashboardLoading('groupList', false);
        })
      );
  }

  setMemberDashboardCertificatesLoading(loading) {
    this.memberDashboardData.certificates.loading = loading;
  }

  setMemberDashboardCertificatesError(error) {
    this.memberDashboardData.certificates.error = error;
  }

  getMemberDashboardCertificates(orgId, userId) {
    this.setMemberDashboardCertificatesLoading(true);
    this.setMemberDashboardCertificatesError(false);
    return Agents.enterprise
      .getMemberCerts(orgId, userId)
      .then(
        action('fetchSuccess', (response) => {
          this.memberDashboardData.certificates.data = response;
          this.setMemberDashboardCertificatesLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setMemberDashboardCertificatesError(error.response || error);
          this.setMemberDashboardCertificatesLoading(false);
        })
      );
  }

  // Transform goal data to be in a similar structure to assignments, so they display similarly
  transformGoalData = (data) => {
    if (!data || !data.length) {
      return data;
    }
    const dataCopy = [...data];
    return dataCopy.map((goal) => {
      const goalCopy = { ...goal };
      // Data from the actual goal contents that we want to show at a top level. For now, goals only have one content item (a path, or a specific course/activity)
      goalCopy.progress = Math.floor(goal.percent_completed);
      if (goal.contents && goal.contents.length) {
        goalCopy.learning_seconds_completed = goal.contents[0].learning_seconds_completed;
        goalCopy.learning_seconds_total = goal.contents[0].duration_seconds;
        goalCopy.item_count = goal.contents.length;
      }
      // If the goal uses a path, need to move some data around so it's in a similar format as assignments were (paths being the top level item)
      // Prevents the goal from expanding and just showing the single path inside - Instead shows all path contents
      if (goal.content_type === 'path') {
        if (goal.contents && goal.contents.length) {
          goalCopy.item_count = goal.contents[0].item_count;
          goalCopy.content_description_id = goal.contents[0].id;
          goalCopy.content_description_title = goal.contents[0].title;
          goalCopy.content_description_type = goal.contents[0].type;
          goalCopy.enrollment_id = goal.contents[0].enrollment_id;
        }
      }
      return goalCopy;
    });
  };

  getMemberDashboardAssignmentGoals(orgId, userId, section) {
    this.setMemberDashboardLoading(section, true);
    this.setMemberDashboardError(section, false);
    const dateFilters = this.getDateFilterParams(this.memberDashboardData.filters.startDate, this.memberDashboardData.filters.endDate);
    if (this.memberDashboardData.filters.range === 'allTime') {
      dateFilters.includeEmpty = 1;
    }

    let query = `?${queryString.stringify(dateFilters)}`;

    // Do not pass a status if "all"
    if (this.memberDashboardData[section].queryParams.status !== 'all') {
      query = `${query}&status=${this.memberDashboardData[section].queryParams.status}`;
    }

    const getMethod = section === 'goals' ? 'getDashboardGoals' : 'getDashboardAssignments';

    return Agents.enterprise[getMethod](orgId, userId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.memberDashboardData[section].data = section === 'goals' ? this.transformGoalData(response) : response;
          this.setMemberDashboardLoading(section, false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          if (error.response.status !== 404) {
            this.memberDashboardData[section].error = error.response || error;
          }
          this.setMemberDashboardLoading(section, false);
        })
      );
  }

  // Transforms content progress included with goals into table
  getDashboardGoalDetailsFromContent(content) {
    const goalTableData = {
      columns: [
        { key: 'content_description_id', type: 'string', showCol: false },
        { key: 'content_description_title', type: 'string', showCol: true, display: 'Title', sortKey: 'content_description_title' },
        { key: 'content_description_type', type: 'string', showCol: true, display: 'Type', sortKey: 'content_description_type' },
        { key: 'content_description_archived_at', type: 'string', showCol: true, display: 'Archived Date' },
        { key: 'vendor', type: 'string', showCol: false, sortKey: 'vendor' },
        { key: 'progress', type: 'string', showCol: true, display: 'Progress', sortKey: 'progress' },
        { key: 'learning_seconds_completed', type: 'duration', showCol: true, display: 'Learning Hours', sortKey: 'learning_seconds_completed' },
        { key: 'created_at', type: 'string', showCol: true, display: 'Start Date', sortKey: 'created_at' },
        { key: 'completed_at', type: 'string', showCol: true, display: 'Completion Date', sortKey: 'completed_at' },
        { key: 'position', type: 'string', showCol: false, sortKey: 'position' },
        { key: 'id', type: 'string', showCol: false },
        { key: 'is_activity', type: 'string', showCol: false },
        { key: 'tags', type: 'string', showCol: false },
      ],
      tableData: [],
    };

    // If for some reson we don't have content, just return empty data (this should never happen)
    if (!content) {
      return Promise.resolve(goalTableData);
    }

    // If we do not have a content_description_id, assumed that we have the children data already. Transform it to table format
    if (content.contents && content.contents.length) {
      const contentRowColumns = [
        'id', // content_description_id
        'title', // content_description_title
        'type', // content_description_type
        'content_description_archived_at',
        'vendor',
        'progress',
        'learning_seconds_completed',
        'created_at',
        'completed_at',
        'position',
        'enrollment_id', // id
        'is_activity',
        'tags',
      ];

      const isProgramType = content.topLevelContent?.content_type?.includes('_program');

      content.contents.forEach((item) => {
        const contentRow = contentRowColumns.map((col) => {
          if (isProgramType) {
            let value = content.topLevelContent[col] || null;

            if (!content.topLevelContent[col] && (item[col] || item[col] === 0)) {
              value = item[col];
            }

            return { value };
          }

          const value = item[col] || item[col] === 0 ? item[col] : null;

          return { value };
        });
        goalTableData.tableData.push(contentRow);
      });
    }
    return Promise.resolve(goalTableData);
  }

  getMemberDashboardAssignmentGoalDetails(teamId, enrollId, contentId, section) {
    const dateFilters = this.getDateFilterParams(this.memberDashboardData.filters.startDate, this.memberDashboardData.filters.endDate);

    if (this.memberDashboardData.filters.range === 'allTime') {
      dateFilters.includeEmpty = 1;
    }

    const queryParams = { ...this.memberDashboardData.goals.queryParams };
    // Goals activity expansion fails if the top level status param is included (assignments progress params didn't previously complain)
    if (section === 'goals') {
      delete queryParams.status;
    }
    const query = `?${queryString.stringify(dateFilters)}&${queryString.stringify(queryParams)}&format=table`;
    if (enrollId) {
      return Agents.enterprise.getDashboardAssignmentDetails(teamId, enrollId, query);
    }
    // We have no enrollment ID (likely a child row that hasn't been started), so just get the content as generic items without any progress data
    return Agents.enrollments.getChildProgress(contentId, query);
  }

  resetMemberDashboardLearningQueryParams() {
    this.memberDashboardData.learning.queryParams = {
      activePg: 1,
      recordsPerPage: 20,
      sortCol: 'content_description_title',
      sortDirection: 'asc',
      status: 'all',
    };
  }

  getMemberDashboardLearning(orgId, userId) {
    this.setMemberDashboardLoading('learning', true);
    this.setMemberDashboardError('learning', false);
    const dateFilters = this.getDateFilterParams(this.memberDashboardData.filters.startDate, this.memberDashboardData.filters.endDate);

    const queryParams = { ...this.memberDashboardData.learning.queryParams };

    if (queryParams.status !== 'all') {
      queryParams.isComplete = queryParams.status === 'completed' ? 1 : 0;
    }
    delete queryParams.status;

    const query = `?${queryString.stringify(dateFilters)}&showScore=1&${queryString.stringify(queryParams)}&format=table`;

    return Agents.enterprise
      .getUnassignedLearning(orgId, userId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.memberDashboardData.learning.data = response;
          this.memberDashboardData.learning.data.pagPagesCount = Math.ceil(this.memberDashboardData.learning.data.totalRecords / queryParams.recordsPerPage);
          this.setMemberDashboardLoading('learning', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.memberDashboardData.learning.error = error.response || error;
          this.setMemberDashboardLoading('learning', false);
        })
      );
  }

  getTeamMemberAllScores = (teamId, userId, contentDescriptionId, params) => {
    const query = params ? `?${queryString.stringify(params)}` : null;
    return Agents.enterprise.getTeamMemberAllScores(teamId, userId, contentDescriptionId, query);
  };

  // *** Assignment Dashboard ***
  assignmentDashboardData = {
    heading: {
      loading: true,
      error: false,
      data: null,
    },
    members: {
      loading: true,
      error: false,
      data: {},
      queryParams: {
        activePg: 1,
        resultsPerPg: 25,
        sortCol: 'name',
        sortDirection: 'asc',
      },
    },
    stats: {},
    filters: {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    },
    config: {
      stats: {
        stat_cards: [
          { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
          { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
          {
            key: 'learningActivities',
            reports: [this.statKeys.activityTypes],
            tableView: true,
          },
          { key: 'assignments', reports: [this.statKeys.assignments], tableView: true },
        ],
        mixed_stat: [{ key: 'learningHoursAssignment', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
      },
    },
  };

  setDefaultAssignmentDashboardData() {
    this.assignmentDashboardData = {
      heading: {
        loading: true,
        error: false,
        data: null,
      },
      members: {
        loading: true,
        error: false,
        data: {},
        queryParams: {
          activePg: 1,
          resultsPerPg: 25,
          sortCol: 'name',
          sortDirection: 'asc',
        },
      },
      stats: {},
      filters: {
        startDate: moment().subtract(30, 'd'),
        endDate: moment(),
      },
      config: {
        stats: {
          stat_cards: [
            { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
            { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
            {
              key: 'learningActivities',
              reports: [this.statKeys.activityTypes],
              tableView: true,
            },
            { key: 'assignments', reports: [this.statKeys.assignments], tableView: true },
          ],
          mixed_stat: [{ key: 'learningHoursAssignment', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
        },
      },
    };
  }

  setAssignmentDashboardLoading(obj, loading) {
    this.assignmentDashboardData[obj].loading = loading;
  }

  setAssignmentDashboardError(obj, error) {
    this.assignmentDashboardData[obj].error = error;
  }

  /**
   * Retrieve assignment dashboard heading data
   */
  getAssignmentDashboardHeading(orgId, assignmentId) {
    this.setAssignmentDashboardLoading('heading', true);
    this.setAssignmentDashboardError('heading', false);

    return Agents.enterprise
      .getAssignment(orgId, assignmentId)
      .then(
        action('fetchSuccess', (response) => {
          this.assignmentDashboardData.heading.data = response;
          this.setAssignmentDashboardLoading('heading', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.assignmentDashboardData.heading.error = error.response || error;
          this.setAssignmentDashboardLoading('heading', false);
        })
      );
  }

  getAssignmentDashboardMembersTable(orgId, userId) {
    this.setAssignmentDashboardLoading('members', true);
    this.setAssignmentDashboardError('members', false);

    const query = `?${queryString.stringify(this.assignmentDashboardData.members.queryParams)}`;

    return Agents.enterprise
      .getAssignmentDashboardMembers(orgId, userId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.assignmentDashboardData.members.data = response;

          this.assignmentDashboardData.members.data.pagPagesCount = Math.ceil(
            this.assignmentDashboardData.members.data.totalRecords / this.assignmentDashboardData.members.queryParams.resultsPerPg
          );
          this.setAssignmentDashboardLoading('members', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.assignmentDashboardData.members.error = error.response || error;
          this.setAssignmentDashboardLoading('members', false);
        })
      );
  }

  // *** Organization Dashboard ***
  orgDashboardData = {
    groupMeta: {
      loading: true,
      error: false,
      data: null,
    },
    stats: {},
    leaderboards: {},
    filters: {
      startDate: moment().subtract(6, 'months'),
      endDate: moment(),
      range: '6months',
    },
    config: {
      stats: {
        stat_cards: [
          { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
          { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
          { key: 'learningActivities', reports: [this.statKeys.activityTypes], tableView: true },
          { key: 'goals', reports: [this.statKeys.goals], tableView: true },
        ],
        half_stat_cards: [
          { key: 'memberActivity', reports: [this.statKeys.members], tableView: true },
          {
            key: 'curriculaActivity',
            reports: ['learning-hours-path'],
            filters: {
              recordsPerPage: 5,
              sortCol: 'learning_hours',
              sortDirection: 'desc',
            },
            tableView: true,
          },
        ],
        mixed_stat: [{ key: 'learningHoursMixed', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
      },
      leaderboards: [
        {
          key: 'member',
          queryParams: {
            resultsPerPg: 10,
            sortCol: 'enrollments',
            sortDirection: 'desc',
          },
        },
        {
          key: 'content',
          queryParams: {
            resultsPerPg: 10,
            sortCol: 'enrollments',
            sortDirection: 'desc',
          },
        },
      ],
    },
  };

  setDefaultOrgDashboardData() {
    this.orgDashboardData = {
      groupMeta: {
        loading: true,
        error: false,
        data: null,
      },
      stats: {},
      leaderboards: {},
      filters: {
        startDate: moment().subtract(6, 'months'),
        endDate: moment(),
        range: '6months',
      },
      config: {
        stats: {
          stat_cards: [
            { key: 'learningHoursSummary', reports: [this.statKeys.learningHours, this.statKeys.ceus], tableView: false },
            { key: 'learningActivityInteractions', reports: [this.statKeys.enrolledActivities], tableView: false },
            { key: 'learningActivities', reports: [this.statKeys.activityTypes], tableView: true },
            { key: 'goals', reports: [this.statKeys.goals], tableView: true },
          ],
          half_stat_cards: [
            { key: 'memberActivity', reports: [this.statKeys.members], tableView: true },
            {
              key: 'curriculaActivity',
              reports: ['learning-hours-path'],
              filters: {
                recordsPerPage: 5,
                sortCol: 'learning_hours',
                sortDirection: 'desc',
              },
              tableView: true,
            },
          ],
          mixed_stat: [{ key: 'learningHoursMixed', reports: [this.statKeys.learningHours], interval: 'time', tableView: true }],
        },
        leaderboards: [
          {
            key: 'member',
            queryParams: {
              resultsPerPg: 10,
              sortCol: 'enrollments',
              sortDirection: 'desc',
            },
          },
          {
            key: 'content',
            queryParams: {
              resultsPerPg: 10,
              sortCol: 'enrollments',
              sortDirection: 'desc',
            },
          },
        ],
      },
    };
  }

  setDashboardLeaderboardFilter = (obj, item, val) => {
    this[obj].leaderboards[item].queryParams.sortCol = val;
  };

  setDashboardLeaderboardQueryParam = (obj, item, queryParam, val) => {
    this[obj].leaderboards[item].queryParams[queryParam] = val;
  };

  setOrgDashboardLoading(obj, loading) {
    this.orgDashboardData[obj].loading = loading;
  }

  setOrgDashboardError(obj, error) {
    this.orgDashboardData[obj].error = error;
  }

  /**
   * Retrieve group meta - For Org/Group Dashboard heading
   */
  getGroupMeta(obj, gId) {
    this.setOrgDashboardLoading('groupMeta', true);
    this.setOrgDashboardError('groupMeta', false);
    return Agents.enterprise
      .getTeamMeta(gId)
      .then(
        action('fetchSuccess', (response) => {
          this[obj].groupMeta.data = response;
          this.setOrgDashboardLoading('groupMeta', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setOrgDashboardError('groupMeta', error.response || error);
          this.setOrgDashboardLoading('groupMeta', false);
        })
      );
  }

  setFilterItem = (obj, filter, val) => {
    if (obj && filter && val) {
      this[obj].filters[filter] = val;
    }
  };

  // *** Content Dashboard ***

  contentDashboardData = {
    heading: {
      loading: true,
      error: false,
      data: null,
    },
    stats: {},
    filters: {
      startDate: moment('2015-01-01'),
      endDate: moment(),
      interval: null,
      range: 'allTime',
    },
    competencyLevels: {
      loading: true,
      error: false,
      data: null,
    },
    workroleData: {
      loading: true,
      error: false,
      data: null,
    },
    contentTranscript: {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPg: 10,
        sortCol: 'name',
        sortDirection: 'asc',
      },
    },
    config: {
      stats: {
        stat_cards: [
          { key: 'learningHoursSummary', reports: [this.statKeys.learningHours], tableView: false },
          { key: 'enrolledActivities', reports: [this.statKeys.enrolledActivities], tableView: false, filters: { includeUnique: 1 } },
          { key: 'activityCompletions', reports: [this.statKeys.completeActivities], tableView: false },
          { key: 'averageScore', reports: [this.statKeys.averageScore], tableView: false },
          { key: 'proficientActivities', reports: [this.statKeys.activityGrades], tableView: false },
        ],
        half_stat_cards: [{ key: 'contentScoreDistribution', reports: [this.statKeys.scoreRange], tableView: true }],
        full_stat: [{ key: 'contentAttempts', reports: [this.statKeys.enrolledActivities], interval: 'time', tableView: true }],
      },
    },
  };

  setDefaultContentDashboardData() {
    this.contentDashboardData = {
      heading: {
        loading: true,
        error: false,
        data: null,
      },
      stats: {},
      filters: {
        startDate: moment('2015-01-01'),
        endDate: moment(),
        interval: null,
        range: 'allTime',
      },
      competencyLevels: {
        loading: true,
        error: false,
        data: null,
      },
      workroleData: {
        loading: true,
        error: false,
        data: null,
      },
      contentTranscript: {
        loading: true,
        error: false,
        queryParams: {
          activePg: 1,
          resultsPerPg: 10,
          sortCol: 'name',
          sortDirection: 'asc',
        },
      },
      config: {
        stats: {
          stat_cards: [
            { key: 'learningHoursSummary', reports: [this.statKeys.learningHours], tableView: false },
            { key: 'enrolledActivities', reports: [this.statKeys.enrolledActivities], tableView: false, filters: { includeUnique: 1 } },
            { key: 'activityCompletions', reports: [this.statKeys.completeActivities], tableView: false },
            { key: 'averageScore', reports: [this.statKeys.averageScore], tableView: false },
            { key: 'proficientActivities', reports: [this.statKeys.activityGrades], tableView: false },
          ],
          half_stat_cards: [{ key: 'contentScoreDistribution', reports: [this.statKeys.scoreRange], tableView: true }],
          full_stat: [{ key: 'contentAttempts', reports: [this.statKeys.enrolledActivities], interval: 'time', tableView: true }],
        },
      },
    };
  }

  setContentDashboardData(obj, data) {
    this.contentDashboardData[obj].data = data;
  }

  setContentDashboardLoading(obj, loading) {
    this.contentDashboardData[obj].loading = loading;
  }

  setContentDashboardError(obj, error) {
    this.contentDashboardData[obj].error = error;
  }

  /**
   * Retrieve content dashboard heading data
   */
  getContentDashboardHeading(contentId) {
    this.setContentDashboardLoading('heading', true);
    this.setContentDashboardError('heading', false);
    return Agents.catalog
      .getContentDescriptionById(contentId)
      .then(
        action('fetchSuccess', (response) => {
          this.setContentDashboardData('heading', response);
          this.setContentDashboardLoading('heading', false);
          return response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setContentDashboardError('heading', error.response || error);
          this.setContentDashboardLoading('heading', false);
        })
      );
  }

  transformCompetencyData = (data) => {
    const formattedData = {};
    if (data && data.length) {
      data.forEach((item) => {
        const skillName = item.skill || '';
        if (!formattedData[skillName]) {
          formattedData[skillName] = [];
        }
        formattedData[skillName].push(item);
      });
    }
    return formattedData;
  };

  /**
   * Retrieve content dashboard competency levels
   */
  getCompetencyLevels(orgId, contentId) {
    const dateFilters = this.getDateFilterParams(this.contentDashboardData.filters.startDate, this.contentDashboardData.filters.endDate);

    const queryParams = {
      ...dateFilters,
      contentDescriptionId: contentId,
    };

    const query = `?${queryString.stringify(queryParams)}`;

    this.setContentDashboardLoading('competencyLevels', true);
    this.setContentDashboardError('competencyLevels', false);
    return Agents.reports
      .getReportData(orgId, 'assessment-skill-grades', query)
      .then(
        action('fetchSuccess', (response) => {
          // Transform data to match format expected in component
          this.setContentDashboardData('competencyLevels', { userGradesBySkill: this.transformCompetencyData(response) });
          this.setContentDashboardLoading('competencyLevels', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setContentDashboardError('competencyLevels', error.response || error);
          this.setContentDashboardLoading('competencyLevels', false);
        })
      );
  }

  /**
   * Retrieve content dashboard transcript
   */
  getContentTranscript(orgId, contentId) {
    const dateFilters = this.getDateFilterParams(this.contentDashboardData.filters.startDate, this.contentDashboardData.filters.endDate);
    const queryParams = {
      ...dateFilters,
      contentDescriptionId: contentId,
      filterAud: 'all',
      ...this.contentDashboardData.contentTranscript.queryParams,
    };

    const query = `?${queryString.stringify(queryParams)}&format=table`;

    this.setContentDashboardLoading('contentTranscript', true);
    this.setContentDashboardError('contentTranscript', false);
    return Agents.reports
      .getReportData(orgId, 'content-dashboard', query)
      .then(
        action('fetchSuccess', (response) => {
          this.contentDashboardData.contentTranscript.tableData = response.tableData;
          this.contentDashboardData.contentTranscript.tableHeadings = response.columns;
          this.contentDashboardData.contentTranscript.recordsCount = response.totalRecords;
          this.contentDashboardData.contentTranscript.pagPagesCount = Math.ceil(response.totalRecords / this.contentDashboardData.contentTranscript.queryParams.resultsPerPg);
          this.setContentDashboardData('contentTranscript', response);
          this.setContentDashboardLoading('contentTranscript', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setContentDashboardError('contentTranscript', error.response || error);
          this.setContentDashboardLoading('contentTranscript', false);
        })
      );
  }

  contentPreferences = {
    certification: [],
    topic: [],
    loading: true,
    error: false,
  };

  setDefaultContentPreferences = () => {
    this.contentPreferences = {
      certification: [],
      topic: [],
      loading: true,
      error: false,
    };
  };

  getContentPreferences = (teamId) => {
    this.contentPreferences.error = false;
    return Agents.enterprise
      .getContentPreferences(teamId, '?categories[]=Certification&categories[]=Topic&verified=1')
      .then(
        action('fetchSuccess', (response) => {
          const certifications = [];
          const topics = [];
          // Split out Certifications vs Topics (skills)
          if (response && response.length) {
            response.forEach((term) => {
              if (term.category === 'Certification') {
                certifications.push(term);
              } else {
                topics.push(term);
              }
            });
          }
          this.contentPreferences.loading = false;
          this.contentPreferences.certification = certifications;
          this.contentPreferences.topic = topics;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.contentPreferences.loading = false;
          this.contentPreferences.error = error;
        })
      );
  };

  addContentPreference = (category, selection, teamId) => {
    // Copy the current content preferences in case request fails after optimistically updating
    const currCategoryPreferences = [...this.contentPreferences[category]];
    // Optimistic update - Add this term to the selected terms
    this.contentPreferences[category].push(selection);
    return Agents.enterprise.setContentPreferences(teamId, { terms: [selection.id] }).catch(
      action('fetchError', (error) => {
        Bugsnag.notify(error);
        // Undo optimistic update
        this.contentPreferences[category] = currCategoryPreferences;
      })
    );
  };

  deleteContentPreference = (category, selection, teamId) => {
    // Copy the current content preferences in case request fails after optimistically updating
    const currCategoryPreferences = [...this.contentPreferences[category]];
    // Optimistic update - Splice this term from the selected terms array
    let selectionIdx = null;
    for (let i = 0; i < this.contentPreferences[category].length; i++) {
      if (this.contentPreferences[category][i].id === selection.id) {
        selectionIdx = i;
        break;
      }
    }
    this.contentPreferences[category].splice(selectionIdx, 1);
    return Agents.enterprise.deleteContentPreferences(teamId, { terms: [selection.id] }).catch(
      action('fetchError', (error) => {
        Bugsnag.notify(error);
        // Undo optimistic update
        this.contentPreferences[category] = currCategoryPreferences;
      })
    );
  };

  enterDemo = () => {
    this.orgDashboardData = DEMO_ENT_STORE_ORG;
    this.teamMemberTableData = DEMO_ENT_STORE_MEMBERS;
    this.curricula = DEMO_ENT_STORE_PATHS;
    this.resetCheckboxState('teamMemberTableData', DEMO_ENT_STORE_MEMBERS.tableData); // Sets the initial state for the checkboxes used in table
    this.resetHeaderCheckbox('teamMemberTableData');
    this.setTeamMemberTableLoading(false);
  };

  exitDemo = () => {
    this.setDefaultOrgDashboardData();
  };
}

export default new EnterpriseStore();
