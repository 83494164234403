import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useQueryParams from '../useQueryParams';
import useCookieMode from './useCookieMode';

/**
 * Beta Cookie Hook
 *
 * @description Utilizes `cyb_beta_features` cookie mode via `useCookieMode` hook that represents beta features that the user has enabled
 *
 * @returns {CookieModeInterface} `cookieModeInterface`
 */
const useBetaCookie = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { enabledContexts: enabledFeatures, enableContext: enableFeature, disableAllContexts: disableAllFeatures } = useCookieMode({ mode: 'cyb_beta_features' });

  /**
   * Beta features are enabled by passing a query param to the url and disabled by clicking the "Exit Beta" button
   */
  useEffect(() => {
    // If the new2023 query param is set, enable the assessments feature.
    // This is the old way of doing feature flags, so to make it easier to migrate, we'll support both for this feature
    const featureId = queryParams?.new2023 ? 'assessments' : queryParams?.beta;

    // If the beta query param is set, enable the featureId
    if (queryParams.beta || queryParams.new2023) {
      enableFeature(featureId);
      // remove the 'beta' query string param in the URL using react router so we don't fire this twice on a reload. Be sure to preserve other query params
      navigate(location.pathname, { replace: true });
    }
  }, [queryParams]);

  /**
   * `BetaCookieInterface` data returned from the hook
   */
  return useMemo(
    () => ({
      enabledFeatures,
      enableFeature,
      disableAllFeatures,
      isAssessmentsBetaEnabled: enabledFeatures.includes('assessments'),
      isBaselineBetaEnabled: enabledFeatures.includes('baseline'),
      isMeasureEnabled: enabledFeatures.includes('measure'),
      isTeamsAssessmentsPathBetaEnabled: enabledFeatures.includes('teams_assessment_paths'),
      isBadgesBetaEnabled: enabledFeatures.includes('badges'),
      isForumsBetaEnabled: enabledFeatures.includes('forums'),
      isCareerProgramsEnabled: enabledFeatures.includes('career_programs'),
      isABMBeta: enabledFeatures.includes('abm'),
      isUserPilotImmersive: enabledFeatures.includes('user_pilot_immersive'),
      isCASSBetaEnabled: enabledFeatures.includes('cass'),
      isEduMilGovBetaEnabled: enabledFeatures.includes('edu_mil_gov'),
      isNewOrgDashboardEnabled: enabledFeatures.includes('new_org_dashboard'),
      isDashboardProgramsFastLoadEnabled: enabledFeatures.includes('dash_fast_programs'),
      isOnboardingBetaEnabled: enabledFeatures.includes('onboarding'),
      isUnenrolledProgramProgressEnabled: enabledFeatures.includes('___unenrolled_program_progress'),
      isTeamsLicenseCheckPromoBetaEnabled: enabledFeatures.includes('teams_license_check'),
      isLearnerViewableLeaderboardEnabled: enabledFeatures.includes('learner_viewable_leaderboard'),
    }),
    [enabledFeatures, enableFeature, disableAllFeatures]
  );
};

export default useBetaCookie;
