import React from 'react';
import moment from 'moment';
import Icon from '../../../components/Icon/Icon';
import { useActivityLimit } from '../../../providers/ActivityLimitProvider';
import If from '../../../components/If/If';
import { HELP_DESK_LINKS } from '../../../constants';
import InterstitialUpgradeOrContinueButtons from './InterstitialUpgradeOrContinueButtons';
import InterstitialUpgradeButtonFooter from './InterstitialUpgradeButtonFooter';
import HelpDeskTrigger from '../../../components/HelpDeskTrigger/HelpDeskTrigger';
import InterstitialContainer from './InterstitialContainer';

const CIP_FEATURES = [
  <div className="flex items-center" key="unlimited-daily-activity-limit">
    Unlimited Daily Usage <HelpDeskTrigger to={HELP_DESK_LINKS.ACTIVITY_LIMIT} className="ml-1" />
  </div>,
  'All Career Paths',
  'All Skill Paths',
  'All Cert Prep Paths',
  'Hands-On Labs',
  'Skill Assessments',
  'Practice Tests',
  'Community Forums',
  'Private Discord Community',
];

export default function ActivityLimitUpgradeInterstitial() {
  const { dailyEnrollmentLimit, momentNextActivityUnlocks } = useActivityLimit();

  const formatTimeRemaining = () => {
    if (!momentNextActivityUnlocks) return '';

    // Get current time in UTC
    const now = moment.utc();
    const unlockTime = moment.utc(momentNextActivityUnlocks);
    const duration = moment.duration(unlockTime.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return `${hours}h ${minutes}m`;
  };

  return (
    <InterstitialContainer>
      <div className="my-4 text-5xl font-black text-black">Great Job, You&apos;re On a Roll!</div>
      <div className="flex justify-center my-2 mx-auto text-[20px] text-black">
        You&apos;ve Reached Your Free Daily Limit of {dailyEnrollmentLimit} Lessons for Today <HelpDeskTrigger />
      </div>
      <InterstitialUpgradeOrContinueButtons canContinue={false} cybSource="ii-activity-limit" />
      <If condition={momentNextActivityUnlocks}>
        <div className="flex justify-center items-center mt-6 w-full text-xs text-gray-700">
          <Icon name="clock" className="mr-1 w-4 h-4 text-gray-700" />
          Come back in <span className="px-1 font-bold">{formatTimeRemaining()}</span> to access this activity
        </div>
      </If>

      <div className="flex justify-center items-center mt-6 text-[24px] font-black text-black">Keep The Momentum Going with Unlimited Access now!</div>

      <div className="flex flex-col py-4 mx-auto mb-8 max-w-240 md:flex-row">
        <div className="flex justify-center items-center w-full md:w-1/2">
          <Icon name="person-shield" className="m-auto max-h-72 text-cyb-pink-500" />
        </div>
        <div className="px-6 w-full md:w-1/2">
          <div className="mt-4 mb-2 font-bold md:mt-0 md:text-left">Unlock All features in Cybrary Basic Access, plus</div>
          {CIP_FEATURES.map((li) => (
            <div className="flex mb-1 ml-4 text-left" key={li}>
              <Icon name="check" className="mr-2 w-6 h-6 text-green-600" />
              <div>{li}</div>
            </div>
          ))}
          <InterstitialUpgradeButtonFooter cybSource="ii-activity-limit" />
        </div>
      </div>
    </InterstitialContainer>
  );
}
