import React, { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import NOV20_OFF_PROMO from '../components/Banners/PromoConfigs/NOV20_OFF_PROMO';
import TWELVE_FOR_SIX_PROMO from '../components/Banners/PromoConfigs/TWELVE_FOR_SIX_PROMO';
import THREE_FOR_TWO_PROMO from '../components/Banners/PromoConfigs/THREE_FOR_TWO_PROMO';
import EXAMPLE_PROMO from '../components/Banners/PromoConfigs/EXAMPLE_PROMO';
import { DEFAULT_COOKIE_SETTINGS, PADDLE_PRICE_ID_CIP_ANNUAL, PADDLE_PRICE_ID_CIP_BIENNIAL, PADDLE_PRICE_ID_CIP_MONTHLY, PADDLE_PRICE_ID_CIP_QUARTERLY } from '../constants';
import GOAL_PROMOS from '../components/Banners/PromoConfigs/GOAL_PROMOS';
import TEAMS_DEMO_PROMOS from '../components/Banners/PromoConfigs/TeamsDemoPromos/TEAMS_DEMO_PROMOS';
import CYBER_WEEK_PROMO from '../components/Banners/PromoConfigs/CYBER_WEEK_PROMO';
import CHECKOUT_ANNUAL_SAVINGS_PROMO from '../components/Banners/PromoConfigs/CHECKOUT_ANNUAL_SAVINGS_PROMO';
import CYBER_NEW_YEAR_PROMO from '../components/Banners/PromoConfigs/CYBER_NEW_YEAR_PROMO';

const PROMOS = {
  EXAMPLE_PROMO,

  /** Teams Demo Promos */
  TEAMS_DEMO_PROMOS_DEFAULT: TEAMS_DEMO_PROMOS.DEFAULT,
  TEAMS_DEMO_PROMOS_MEMBERS: TEAMS_DEMO_PROMOS.MEMBERS,
  TEAMS_DEMO_PROMOS_PATHS: TEAMS_DEMO_PROMOS.PATHS,
  TEAMS_DEMO_PROMOS_GOALS: TEAMS_DEMO_PROMOS.GOALS,
  TEAMS_DEMO_PROMOS_REPORTING: TEAMS_DEMO_PROMOS.REPORTING,
  TEAMS_DEMO_PROMOS_SETTINGS: TEAMS_DEMO_PROMOS.SETTINGS,
  TEAMS_DEMO_PROMOS_LOGS: TEAMS_DEMO_PROMOS.LOGS,

  /** CIP Promos */

  CYBER_NEW_YEAR_PROMO,
  CYBER_WEEK_PROMO,
  NOV20_OFF_PROMO,
  TWELVE_FOR_SIX_PROMO,
  THREE_FOR_TWO_PROMO,
  CHECKOUT_ANNUAL_SAVINGS_PROMO,

  /** Default Promo */
  default: {
    isEnabled: true,
    isShownCipFree: true,
    title: `Cybrary Insider Pro`,
    body: `Upgrade to practice the hands-on skills employers are hiring for. Unlock access to practice tests, virtual labs, career paths, and much more.`,
    backgroundImageUrl: 'https://images.ctfassets.net/kvf8rpi09wgk/6LclmBm4xFh9uJDS76r9Lu/f66758fd24db73530e8fd903e2ab26df/CRISC_bg.png',
    backgroundImageFade: 0.4,
  },
};

const PromoContext = createContext();

function PromoProviderComponent({ children, userStore, profileStore }) {
  const { isEnterprise, isCip, isFree, user, team, subscriptions } = userStore;
  const activeSubscription = subscriptions?.activeSubscription;
  const { goal } = user?.onboarding_data || {};
  const { isEduGovMilUser, isPublicEmailOnlyUser } = profileStore;

  const [isHeaderBannerDismissed, setIsHeaderBannerDismissed] = useState(false);
  const location = useLocation();

  const dismissPromo = (dismissedKey, delayOnDismiss = 60) => {
    if (dismissedKey) {
      Cookies.set(`cyb_promo_${dismissedKey}`, 'true', { ...DEFAULT_COOKIE_SETTINGS, expires: moment().add(delayOnDismiss, 'minutes').toDate() });
    }
  };

  const activePromo = useMemo(() => {
    // Filter out disabled promos
    const enabledPromos = Object.values(PROMOS).filter((promoObject) => promoObject.isEnabled);

    // Determine which promos to show based on the user's state
    const isUserTeamLearner = isEnterprise && team?.role === 'team-member';
    const isUserTeamAdmin = isEnterprise && team?.role === 'team-admin';
    const isUserTeamOwner = isEnterprise && team?.role === 'org-owner';
    const isUserCipFree = !isEnterprise && isFree;
    const isUserCipMonthly = isCip && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_MONTHLY;
    const isUserCipQuarterly = isCip && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_QUARTERLY;
    const isUserCipAnnual = isCip && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_ANNUAL;
    const isUserCipBiennial = isCip && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_BIENNIAL;
    const isUserCipEduGovMilFree = !isEnterprise && isFree && isEduGovMilUser;
    const isUserCipEduGovMilMonthly = !isEnterprise && isEduGovMilUser && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_MONTHLY;
    const isUserCipEduGovMilQuarterly = !isEnterprise && isEduGovMilUser && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_QUARTERLY;
    const isUserCipEduGovMilAnnual = !isEnterprise && isEduGovMilUser && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_ANNUAL;
    const isUserCipEduGovMilBiennial = !isEnterprise && isEduGovMilUser && activeSubscription?.price_id === PADDLE_PRICE_ID_CIP_BIENNIAL;

    let eligiblePromos = enabledPromos.filter((promoObject) => {
      return (
        (promoObject.isShownTeamLearner && isUserTeamLearner) ||
        (promoObject.isShownTeamAdmin && isUserTeamAdmin) ||
        (promoObject.isShownTeamOwner && isUserTeamOwner) ||
        (promoObject.isShownCipFree && isUserCipFree) ||
        (promoObject.isShownCipMonthly && isUserCipMonthly) ||
        (promoObject.isShownCipQuarterly && isUserCipQuarterly) ||
        (promoObject.isShownCipAnnual && isUserCipAnnual) ||
        (promoObject.isShownCipBiennial && isUserCipBiennial) ||
        (promoObject.isShownCipEduGovMilFree && isUserCipEduGovMilFree) ||
        (promoObject.isShownCipEduGovMilMonthly && isUserCipEduGovMilMonthly) ||
        (promoObject.isShownCipEduGovMilQuarterly && isUserCipEduGovMilQuarterly) ||
        (promoObject.isShownCipEduGovMilAnnual && isUserCipEduGovMilAnnual) ||
        (promoObject.isShownCipEduGovMilBiennial && isUserCipEduGovMilBiennial)
      );
    });

    // Filter out promos that are not currently running
    const today = moment();
    eligiblePromos = eligiblePromos.filter(({ startDate, endDate }) => {
      // If before start date, don't show
      if (startDate && today.isBefore(moment(startDate))) {
        return false;
      }
      // If after end date, don't show
      if (endDate && today.isAfter(moment(endDate))) {
        return false;
      }
      return true;
    });

    // Filter out promos that are not shown on the current page
    eligiblePromos = eligiblePromos.filter((promoObject) => {
      if (!promoObject.isShownOnPages) {
        return true;
      }
      return promoObject?.isShownOnPages?.some?.((page) => location.pathname.match(page));
    });

    // Filter out promos that are not shown to the user based on a/b testing
    eligiblePromos = eligiblePromos.filter((promoObject) => {
      if (!promoObject?.abUserIdGroups) {
        return true;
      }
      const userIdGroup = parseInt(user?.id?.toString()?.slice?.(-1) || '0', 10);
      return promoObject?.abUserIdGroups?.includes?.(userIdGroup);
    });

    // Filter out promos that are not shown to the user based on a/b testing chance
    eligiblePromos = eligiblePromos.filter((promoObject) => {
      if (!promoObject?.abShowChance) {
        return true;
      }
      // abShowChance is a number between 0.0 and 1.0 (0% and 100%)
      return Math.random() < (promoObject?.abShowChance || 0);
    });

    // Filter out dismissed promos by checking the cookie
    eligiblePromos = eligiblePromos.filter((promoObject) => {
      // No dismissed key means the promo is not dismissible
      if (!promoObject.dismissedKey || !promoObject.isDismissible) {
        return true;
      }
      const dismissedKey = `cyb_promo_${promoObject.dismissedKey}`;
      return !Cookies.get(dismissedKey);
    });

    if (eligiblePromos?.length) {
      // Sort by priority (higher priority is shown first)
      eligiblePromos.sort((a, b) => b.priority - a.priority);

      // Append a/b test groups to spLabel if we have them
      const promo = {
        ...eligiblePromos[0],
      };
      if (promo.abUserIdGroups) {
        promo.spLabel = `${promo.spLabel}|group${promo.abUserIdGroups?.join?.('')}`;
      }

      return promo;
    }

    // If no eligible promos and isUserCipFree, default to the goals promo from onboarding choices
    if (isUserCipFree) {
      switch (goal) {
        case 'Launch My Career':
          return GOAL_PROMOS.launchMyCareer?.isEnabled ? GOAL_PROMOS.launchMyCareer : null;
        case 'Get a Certification':
          return GOAL_PROMOS.getCertification?.isEnabled ? GOAL_PROMOS.getCertification : null;
        case 'Upskill & Practice':
          return GOAL_PROMOS.upskillPractice?.isEnabled ? GOAL_PROMOS.upskillPractice : null;
        default:
          return null;
      }
    }
    return null;
  }, [goal, activeSubscription, isEnterprise, team?.role, isEduGovMilUser, isPublicEmailOnlyUser, userStore?.user, location.pathname]);

  useEffect(() => {
    // Fetch user accounts to ensure we have the latest email accounts
    if (user?.id) {
      profileStore.getUserAccounts();
    }
  }, [user?.id]);

  // values returned to be used in the user's profile page
  const values = useMemo(
    () => ({
      activePromo,
      dismissPromo,
      isHeaderBannerDismissed,
      setIsHeaderBannerDismissed,
    }),
    [activePromo, isHeaderBannerDismissed]
  );

  return <PromoContext.Provider value={values}>{children}</PromoContext.Provider>;
}

export const usePromo = () => useContext(PromoContext);
const PromoProvider = inject('userStore', 'profileStore')(observer(PromoProviderComponent));
export default PromoProvider;
