import React from 'react';
import { inject, observer } from 'mobx-react';
import Checkbox from '../../components/FormFields/Checkbox';

const ManageTeamFacingLeaderboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore'
)(
  observer(({ userStore, commonStore, enterpriseStore, team }) => {
    const { show_team_leaderboard, id: orgId } = team;

    const isTeamLeaderboardShown = show_team_leaderboard ? <span className="text-cyb-green-600">Enabled</span> : <span className="text-cyb-red-700">Disabled</span>;

    const editTeamSettings = (setting) => {
      enterpriseStore
        .editOrgSettings(orgId, setting)
        .then(() => {
          commonStore.triggerToast('success', {
            content: 'Settings Updated',
          });
          userStore.loadAndSetTeamData(orgId);
        })
        .catch((e) => {
          commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        });
    };

    const toggleTeamLeaderboardAccessConfirm = (_e, { checked }) => {
      let confirmText;
      if (checked) {
        confirmText = 'Are you sure you want to allow members access to the team leaderboard?';
      } else {
        confirmText = 'Are you sure you want to disable members access to the team leaderboard?';
      }
      commonStore.triggerConfirm({
        content: confirmText,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          editTeamSettings({ show_team_leaderboard: checked });
        },
      });
    };

    return (
      <div className="flex items-center mt-4 text-sm">
        <Checkbox
          className="flex items-start mr-6 mb-2"
          onChange={toggleTeamLeaderboardAccessConfirm}
          checked={Boolean(show_team_leaderboard)}
          ariaLabelledBy="team-leaderboard-access-toggle"
          toggle
        />
        <p id="team-leaderboard-access-toggle">Allow members access to the team leaderboard: {isTeamLeaderboardShown}</p>
      </div>
    );
  })
);

export default ManageTeamFacingLeaderboard;
