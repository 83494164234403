import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Title from '../Title/Title';
import Loading from '../Loading/Loading';
import FormatUtil from '../../utils/formatUtil';
import StyledError from '../Error/StyledError';
import Container from '../Container/Container';
import UPTrigger from '../UPTrigger/UPTrigger';

function GetGroupMembersCount({ data, groupScope }) {
  if (!data || !data.member_count) {
    return null;
  }

  return (
    <p className="mb-0 text-gray-600">
      <span className="font-semibold text-black">{FormatUtil.formatNumbers(data.member_count)}</span> {groupScope ? 'group' : 'team'} {data.member_count > 1 ? 'members' : 'member'}
    </p>
  );
}

const OrganizationDashboardHeading = observer(({ data, loading, error, groupScope, canManageAdmins, team }) => {
  if ((groupScope && !!loading) || (!groupScope && !data)) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (groupScope && !!error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  const isDemo = team.id === 'demo';
  const handleZETrigger = () => (!isDemo && window.zE ? window.zE('webWidget', 'open') : null);
  return (
    <div className="flex flex-col px-8 pt-12 print:pt-4 print:pb-0 pb-6 lg:flex-row header-container">
      <div className="print:flex grow lg:flex">
        {data.logo_url && (
          <div className="mr-8 mb-8 sm:mb-0 header-image">
            <img style={{ maxWidth: '140px' }} src={data.logo_url} alt="Logo" />
          </div>
        )}
        <div className="w-full">
          <div className="print:flex items-center mb-2 sm:flex">
            <div className="flex-1 grow sm:mb-0">
              <div className="flex-1 print:mb-4 mb-8 sm:mb-0">
                <Title title={data.name} omitPadding />
              </div>
              <GetGroupMembersCount data={data} groupScope={groupScope} />
              {!!data.max_seats && !!data.active_seats && (
                <p className="mb-0 text-gray-600">
                  <span className="font-semibold text-black">{FormatUtil.formatNumbers(data.active_seats)}</span> of{' '}
                  <span className="font-semibold text-black">{FormatUtil.formatNumbers(data.max_seats)}</span> licenses used
                </p>
              )}
              <p className="mb-0 text-gray-600">
                <span className="font-semibold text-black">Created: </span>
                {moment(data.created_at).format('MM/DD/YYYY')}
              </p>
            </div>
            <div className="flex gap-x-4 items-center my-4 sm:flex-col sm:gap-y-4 sm:items-end sm:my-0 sm:ml-4 sm:text-right">
              {canManageAdmins && !groupScope && !isDemo && (
                <div className="flex items-start my-4">
                  <button
                    className={`print:hidden py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm ${
                      isDemo ? 'cursor-not-allowed' : ''
                    }`}
                    onClick={handleZETrigger}
                    disabled={isDemo}
                  >
                    + Add Licenses
                  </button>
                  <UPTrigger guideId={null} onClick={handleZETrigger} className="-mt-5 -ml-1" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OrganizationDashboardHeading;
