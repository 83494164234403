import React from 'react';
import AddLink from '../../AddLink/AddLink';
import { WWW_PRICING_PAGE } from '../../../constants';

function Footer({ userStore }) {
  const { isFree, isUserOnActiveTeam } = userStore;
  return (
    <div className="flex absolute bottom-0 justify-between items-center py-2 pr-4 pl-6 w-full bg-white border border-t border-gray-300">
      <AddLink to="/logout" className="p-4 text-center text-gray-600 hover:text-black cursor-pointer">
        Logout
      </AddLink>
      {!isFree || isUserOnActiveTeam ? null : (
        <AddLink
          to={`${WWW_PRICING_PAGE}/?appfooter=btn`}
          className="inline-flex items-center py-2.5 px-6 text-sm font-bold leading-5 text-center text-white
            hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm focus:outline-none cursor-pointer"
        >
          Upgrade
        </AddLink>
      )}
    </div>
  );
}

export default Footer;
