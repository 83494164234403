import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from '../Router/withRouter';
import AddLink from '../AddLink/AddLink';
import RequestLicenseTeamSelect from './RequestLicenseTeamSelect';
import Modal from '../Modal/Modal';
import Divider from '../Divider/Divider';

import './upgrade-button.css';
import Button from '../Button/Button';
import { WWW_PRICING_PAGE } from '../../constants';

function EnterpriseUpgradeOptions({ activeTeams, userTeams, upgradeLink, getRequestLicenseLink, hasPremiumContentLicense, openChat }) {
  if (hasPremiumContentLicense) {
    return (
      <>
        <p>This learning activity requires a cybersecurity content license. Contact your Customer Success Manager to upgrade your account</p>
        <button
          onClick={() => openChat()}
          className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
        >
          Upgrade
        </button>
      </>
    );
  }

  const multipleActiveTeams = activeTeams?.length > 1;
  return (
    <div className="flex flex-col justify-between items-center md:flex-row">
      <div className={`${multipleActiveTeams ? 'align-middle' : 'align-bottom'} md:w-2/5`}>
        <p>Upgrade to Cybrary Insider Pro</p>
        <AddLink to={upgradeLink}>
          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">Upgrade</button>
        </AddLink>
      </div>
      <Divider className="block self-stretch py-8 md:hidden" horizontal>
        OR
      </Divider>
      <Divider className="hidden self-stretch md:flex" vertical>
        OR
      </Divider>
      <div className="md:w-1/2">
        <RequestLicenseTeamSelect activeTeams={activeTeams} userTeams={userTeams} getRequestLicenseLink={getRequestLicenseLink} />
      </div>
    </div>
  );
}

const openChat = () => {
  window.zE('webWidget', 'open');
  window.zE('webWidget', 'show');
};

const UpgradeButton = inject(
  'userStore',
  'commonStore'
)(
  observer(function UpgradeButton({ commonStore, userStore, upgradeText, upgradeLinkParams, openChatOnly, color, buttonClasses, icon }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isEnterprise, userTeams, activeTeams } = userStore;

    // If openChatOnly prop is provided, do nothing more than open chat window
    if (openChatOnly) {
      return (
        <Button onClick={openChat} color={color} icon={icon}>
          {upgradeText}
        </Button>
      );
    }

    const upgradeLink = `${WWW_PRICING_PAGE}/${upgradeLinkParams}`;

    // If not enterprise, or is enterprise but with no active teams, show only upgrade button
    const needUpgrade = !isEnterprise || (isEnterprise && !activeTeams?.length);
    if (needUpgrade) {
      return (
        <Button href={upgradeLink} color={color} className={buttonClasses} icon={icon}>
          {upgradeText}
        </Button>
      );
    }

    // If user is on an active team(s) show both the upgrade button and request license links in a modal
    return (
      <Modal
        size="lg"
        position="center"
        className="overflow-none "
        ariaLabel="Upgrade Options"
        trigger={
          <Button onClick={() => setIsModalOpen(true)} color={color} icon={icon}>
            {upgradeText}
          </Button>
        }
        toggle={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <div className="p-4 text-center">
          <h2 className="text-2xl font-black">Unlock unlimited learning</h2>
          <div className="py-4">
            <EnterpriseUpgradeOptions
              openChat={openChat}
              activeTeams={activeTeams}
              userTeams={userTeams}
              upgradeLink={upgradeLink}
              getRequestLicenseLink={commonStore.getRequestLicenseLink}
              hasPremiumContentLicense={userStore.hasPremiumContentLicense}
            />
          </div>
        </div>
      </Modal>
    );
  })
);

export default withRouter(UpgradeButton);
