import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AddLink from '../../components/AddLink/AddLink';
import Container from '../../components/Container/Container';
import { WWW_PRICING_PAGE } from '../../constants';

function CybraryDiscordLogos() {
  return (
    <div className="flex flex-col justify-center items-center pb-8 md:flex-row">
      <img
        alt="Cybrary Logo"
        src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
        className="mx-4 w-48"
      />
      <div className="text-xl font-semibold md:m-4">+</div>
      <img
        src="https://images.ctfassets.net/kvf8rpi09wgk/3ej3DL12wWRGAOTOfiMyJV/9bec8a73eab6f8b235e54fba0178fd3d/Discord-Logo_Wordmark-Color.svg"
        alt="Discord Logo"
        className="w-48"
      />
    </div>
  );
}

function CIPView() {
  return (
    <>
      <CybraryDiscordLogos />
      <div className="pb-8 text-2xl font-black text-center md:text-3xl lg:text-4xl">Our Discord Community is part of Cybrary Insider Pro</div>
      <p className="px-4 pb-16 text-center md:px-8">
        The Cybrary Insider Pro Discord community is the place for IT and cybersecurity professionals to connect with each other and mentors and to exchange ideas about the
        careers, training tips, industry trends, technologies, best practices, or anything and everything IT and cybersecurity related.
      </p>
      <div className="flex justify-center items-center">
        <AddLink to="https://discord.gg/dmFFGfQJHz" target="_black" rel="noopener noreferrer">
          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm">
            Join the Community
          </button>
        </AddLink>
      </div>
    </>
  );
}

function FreeView() {
  return (
    <>
      <CybraryDiscordLogos />
      <div className="pb-8 text-2xl font-black text-center md:text-3xl lg:text-4xl">
        Our Discord Community is a members-only benefit, available with a Cybrary Insider Pro subscription
      </div>
      <p className="px-4 pb-8 text-center md:px-8">
        Join other students, professionals and a group of dedicated mentors to exchange ideas about careers, training tips, industry trends, technologies, best practices, or
        anything and everything cybersecurity-related.
      </p>
      <div className="flex justify-center items-center my-8 mx-auto max-w-screen-md">
        <AddLink
          className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
          to={`${WWW_PRICING_PAGE}/?appnav=discordbtn`}
        >
          Upgrade to Cybrary Insider Pro
        </AddLink>
      </div>
    </>
  );
}

function WhichView({ userType, isUserOnActiveTeam }) {
  if (userType === 'cip') {
    return <CIPView />;
  }
  if (userType === 'free' && !isUserOnActiveTeam) {
    return <FreeView />;
  }

  return null;
}

const Community = inject(
  'authStore',
  'commonStore',
  'userStore',
  'awardsStore'
)(
  observer(
    class Community extends Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Community | Cybrary');
        // If the user has an onboarding checklist, mark visit to this page complete
        if (this.props.awardsStore.getChecklistByName('onboarding')) {
          this.props.awardsStore.postChecklistItem('onboarding', 'visit_community_page');
        }
      }

      render() {
        const { userType, isUserOnActiveTeam } = this.props.userStore;
        return (
          <Container size="md-1280" className="py-16">
            <div id="marketing-page-template">
              <WhichView userType={userType} isUserOnActiveTeam={isUserOnActiveTeam} />
            </div>
          </Container>
        );
      }
    }
  )
);

export default Community;
