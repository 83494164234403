import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import AddLink from '../AddLink/AddLink';
import { HELP_DESK_LINKS } from '../../constants';
import HelpIcon from '../../Icons/HelpIcon';

/**
 * A component that renders a help icon that links to the help desk.
 * @param {string} className - The class name for the container element.
 * @param {string} iconClassName - The class name for the icon element.
 * @param {string} to - The URL to link to.
 * @returns {JSX.Element} - The HelpDeskTrigger component.
 */
function HelpDeskTrigger({ className, iconClassName, to = HELP_DESK_LINKS.HOMEPAGE }) {
  const iconClasses = useMemo(() => twMerge('w-4 h-4 text-gray-700', iconClassName), [iconClassName]);
  return (
    <AddLink target="_blank" to={to} aria-label="Help Center opens in new window" className={className}>
      <HelpIcon classes={iconClasses} />
    </AddLink>
  );
}

export default HelpDeskTrigger;
