import React, { useState } from 'react';
import FeedbackPane from '../../Feedback/FeedbackPane';
import Icon from '../../Icon/Icon';
import SharePane from '../../Share/SharePane';
import Outline from '../Outline';
import TabsMenu from '../TabsMenu';
import UserStatsHeader from '../UserStatsHeader';

function Menu({ activeMenuId, resetMenu, pane }) {
  const label = {
    share: 'Share',
    feedback: 'Review this course',
  };

  return (
    <>
      <div className="flex items-center py-7 px-6 mx-auto mb-6 h-16 bg-white border-b-xs border-gray-400">
        <button type="button" aria-label="Previous menu" className="focus:outline-none" onClick={resetMenu}>
          <Icon name="chevron-left" className="mr-4 w-5 h-5 text-black" />
        </button>
        <div className="flex justify-center items-center w-full">
          <p className="text-lg font-semibold text-black">{label[activeMenuId]}</p>
        </div>
      </div>
      {pane[activeMenuId]}
    </>
  );
}

/** Left Sidebar */
function MobilePrimarySidebar({
  isOpen,
  toggleMobilePrimarySidebar,
  activity,
  title,
  type,
  permalinkPrefix,
  permalink,
  canAccessActivity,
  desktopTabMenu,
  hasIncompleteItems,
  tasks = null,
  disableNav = false,
}) {
  const [activeMenuId, setActiveMenuId] = useState('');

  const { id: activityId, previousActivity, nextActivity, activityTitle } = activity;

  return (
    <div className={`fixed top-0 left-0 w-full h-full lg:hidden transition-all duration-300 z-20 ${isOpen ? 'visible' : 'invisible'}`}>
      <button onClick={() => toggleMobilePrimarySidebar(!isOpen)} aria-label="sidebar overlay" className="w-full h-full">
        <div id="sidebar-overlay" className="z-30 w-full h-full bg-black/50" />
      </button>

      <div className={`overflow-hidden absolute top-0 left-0 z-40 bg-white w-4/5 h-full transition-all ease-in-out duration-500 ${isOpen ? 'ml-0' : '-ml-152'}`}>
        <div className="overflow-y-scroll pb-10 h-full second-container" style={{ width: '103%' }}>
          {!activeMenuId ? (
            <>
              <div className="flex fixed top-0 z-10 items-center py-7 px-6 mb-0 w-4/5 h-16 bg-white border-b-xs border-gray-400">
                <UserStatsHeader wrapperClasses="pr-0" />
              </div>
              <TabsMenu
                isSidebar
                tabs={desktopTabMenu}
                tasks={tasks}
                outline={
                  <Outline
                    title={activityTitle}
                    currentActivity={activityId}
                    previous={previousActivity}
                    next={nextActivity}
                    canAccessActivity={canAccessActivity}
                    isSidebar
                    isIncompleteOutline={hasIncompleteItems}
                    disableNav={disableNav}
                  />
                }
              />
            </>
          ) : (
            <Menu
              activeMenuId={activeMenuId}
              resetMenu={() => setActiveMenuId('')}
              pane={{
                share: <SharePane permalink={permalink} prefix={permalinkPrefix} title={title} type={type} id="mobile" isSidebar />,
                feedback: <FeedbackPane isSidebar feedbackFormId="mobile_primary_sidebar_review" toggleFeedbackDisplay={() => setActiveMenuId('')} />,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MobilePrimarySidebar;
