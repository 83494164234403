import React from 'react';
import { getCN } from '../../utils/formatUtil';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';

function getButtonColors(color) {
  switch (color) {
    case 'transparent-gray':
      return {
        main: 'bg-transparent text-black',
        bgHover: 'hover:underline',
      };
    case 'gray':
      return {
        main: 'bg-gray-200 text-black',
        bgHover: 'hover:bg-gray-300',
      };
    case 'medium-gray':
      return {
        main: 'bg-gray-300 text-black',
        bgHover: 'hover:bg-gray-400',
      };
    case 'light-gray':
      return {
        main: 'bg-gray-100 text-black',
        bgHover: 'hover:bg-gray-200',
      };
    case 'dark-gray':
      return {
        main: 'bg-gray-600 text-white',
        bgHover: 'hover:bg-gray-700',
      };
    case 'green':
      return {
        main: 'bg-cyb-green-500 text-white',
        bgHover: 'hover:bg-cyb-green-600',
      };
    case 'red':
      return {
        main: 'bg-cyb-red-700 text-white',
        bgHover: 'hover:bg-red-600',
      };
    case 'pink':
    default:
      return {
        main: 'bg-cyb-pink-500 text-white',
        bgHover: 'hover:bg-cyb-pink-600',
      };
  }
}

function Button({ id, type, color, text, children, icon, className, onClick, loading, loadingProps = {}, href, target, disabled, style, ariaLabel, title }) {
  const btnType = type === 'submit' ? 'submit' : 'button';

  const btnColor = getButtonColors(color);

  const buttonClass = getCN(`
    leading-5 py-2.5 px-6 text-sm font-bold border-sm text-center
    bg-center items-center rounded-sm
    ${className}
    ${icon && 'flex'}
    ${btnColor.main}
    ${!disabled && btnColor.bgHover}
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
  `);

  const iconClass = getCN(`
    ${(icon && icon.className) || 'h-6 w-6'}
    ${icon?.position === 'right' ? 'ml-2' : ''}
    ${icon?.position === 'left' ? 'mr-2' : ''}
  `);

  const handleClick = (e) => {
    if (href) {
      window.open(href, target || '_self');
    } else if (onClick) {
      onClick(e);
    }
  };

  const onClickProp = !disabled && !!handleClick ? { onClick: handleClick } : {};

  const content = children || <span>{text}</span>;

  const loadingClass = getCN(`
    ${loadingProps.className || 'h-3 w-3 border-2'}
  `);

  return (
    <button id={id} aria-label={ariaLabel} title={title} type={btnType} style={style} disabled={disabled} className={buttonClass} {...onClickProp}>
      {icon && !loading && icon.position !== 'right' ? <Icon name={icon.name} className={iconClass} /> : null}

      {loading ? (
        <Loading
          className={loadingClass}
          message={loadingProps.message}
          wrapperClassName={loadingProps.wrapperClassName ? loadingProps.wrapperClassName : 'py-0'}
          color={loadingProps.color ? loadingProps.color : 'white'}
          messageClassName={loadingProps.messageClassName}
        />
      ) : (
        content
      )}

      {icon && !loading && icon.position === 'right' ? <Icon name={icon.name} className={iconClass} /> : null}
    </button>
  );
}

export default Button;
