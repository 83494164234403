import React from 'react';
import moment from 'moment';
import Header from '../Header/Header';
import Segment from '../Segment/Segment';
import Awards from '../Awards/Awards';
import StyledError from '../Error/StyledError';
import Loading from '../Loading/Loading';

function DisplayCerts({ certificates, filterStartDate, filterEndDate }) {
  const { loading, error, data } = certificates;
  const noCerts = (
    <Segment className="px-0 border-none">
      <Header as="h2">Certificates of Completion</Header>
      <p>There are no activities for this user during this time period</p>
    </Segment>
  );

  const certsObj = {
    'Career Path': [],
    Course: [],
    MicroCourse: [],
  };

  // Check if there were any certs completed within the current date range filter
  Object.keys(data).forEach((type) => {
    // Loop over the different cert types (Course, Microcourse)
    data[type].forEach((cert) => {
      // Loop over each individual cert
      if (!filterStartDate || !filterEndDate || (moment(cert.issued_at).isAfter(filterStartDate) && moment(cert.issued_at).isBefore(filterEndDate))) {
        if (typeof certsObj[type] === 'undefined') {
          certsObj[type] = [];
        }
        certsObj[type].push(cert);
      }
    });
  });

  if (loading) {
    return (
      <Segment className="px-0 border-none">
        <Header as="h2">Certificates of Completion</Header>
        <Loading message="Loading..." />
      </Segment>
    );
  }
  if (error) {
    return (
      <Segment className="px-0 border-none">
        <Header as="h2">Certificates of Completion</Header>
        <StyledError error={error} />
      </Segment>
    );
  }
  if (!certsObj['Career Path'].length && !certsObj.Course.length && !certsObj.MicroCourse.length) {
    return noCerts;
  }
  const contentTypes = Object.keys(certsObj);
  return (
    <Segment className="px-0 border-none">
      <Header as="h2">Certificates of Completion</Header>
      {contentTypes.map((type) => {
        if (!certsObj[type].length) {
          return null;
        }
        return (
          <div key={type} className="mb-6">
            <Header as="h3">{type}s</Header>
            <Awards awardType="certificates" awards={certsObj[type]} />
          </div>
        );
      })}
    </Segment>
  );
}

export default DisplayCerts;
