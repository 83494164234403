/**
 * This provider is used to track the activity limit for a user.
 * This is based on activity enrollments created within the last day.
 * Each user has a limit of X enrollments per day.
 * Maybe more for edu?
 */

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import agents from '../agents/agents';

const ActivityLimitContext = createContext();

const ONBOARDING_ACTIVITY_IDS = [75880, 75730, 75741, 75742, 75743, 75788];
function ActivityLimitProviderComponent({ userStore, children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [enrollmentsInLastDay, setEnrollmentsInLastDay] = useState([]);
  // Making this it's own variable so we can offer different limits for different users
  const dailyEnrollmentLimit = 10;

  // Loads the enrollments in the last day
  const loadEnrollmentsInLastDay = useCallback(() => {
    (async () => {
      // Skip if the user is paid or logged out
      if (!userStore?.user || userStore?.user?.is_paid) return;
      setIsLoading(true);
      try {
        const query = queryString.stringify({
          isActivity: 1,
          isRoot: 0,
          sortCol: 'completed_at',
          sortDirection: 'desc',
          // In case the user finds a way over the limit, we'll just load the last 50 and
          // reverse them to get the oldest enrollments in a 24H period first
          // This way even if the user somehow manages to enroll in more than the limit,
          // they'll still only be shown the oldest enrollments in the last day and not the newest ones they shouldn't be seeing.
          limit: 50,
        });
        const enrollments = await agents.enrollments.getEnrollmentsList(`?${query}`);
        const now = moment.utc();
        const oneDayAgo = now.subtract(1, 'day');

        const filteredEnrollments = enrollments
          // filter out any enrollments past 24 hours old
          ?.filter((enrollment) => {
            const completed_at = moment.utc(enrollment.completed_at);
            return completed_at.isAfter(oneDayAgo);
          })
          // filter out any onboarding activities
          ?.filter((enrollment) => !ONBOARDING_ACTIVITY_IDS.includes(enrollment.content_description_id))
          // invert sorting to get the oldest enrollments in the last day first
          ?.reverse()
          // limit to the daily enrollment limit
          ?.slice(0, dailyEnrollmentLimit);
        setEnrollmentsInLastDay(filteredEnrollments || []);
      } catch (error) {
        Bugsnag.notify(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userStore?.user]);

  const isAtLimit = useMemo(() => {
    return enrollmentsInLastDay?.length >= dailyEnrollmentLimit;
  }, [enrollmentsInLastDay, dailyEnrollmentLimit]);

  const momentNextActivityUnlocks = useMemo(() => {
    if (!enrollmentsInLastDay?.length) return null;
    const oldestEnrollment = enrollmentsInLastDay[0];
    return moment.utc(oldestEnrollment.completed_at).add(1, 'day');
  }, [enrollmentsInLastDay?.[0]?.completed_at]);

  useEffect(() => {
    loadEnrollmentsInLastDay();
  }, [userStore?.user?.id]);

  const values = useMemo(
    () => ({ dailyEnrollmentLimit, enrollmentsInLastDay, loadEnrollmentsInLastDay, isLoading, isAtLimit, momentNextActivityUnlocks }),
    [enrollmentsInLastDay, isLoading, momentNextActivityUnlocks, dailyEnrollmentLimit, isAtLimit]
  );

  return <ActivityLimitContext.Provider value={values}>{children}</ActivityLimitContext.Provider>;
}

const ActivityLimitProvider = inject('userStore')(observer(ActivityLimitProviderComponent));

export const useActivityLimit = () => useContext(ActivityLimitContext);

export default ActivityLimitProvider;
