import React, { useMemo, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { Fade, Flip } from 'react-awesome-reveal';
import { inject, observer } from 'mobx-react';
import UpgradePromoBanner from './UpgradePromoBanner';
import Modal from '../Modal/Modal';
import Header from '../Header/Header';
import CybraryLogo from '../CybraryLogo/CybraryLogo';
import ValidationUtil from '../../utils/validationUtil';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import agents from '../../agents/agents';
import AddLink from '../AddLink/AddLink';

function ErrorMessage({ error }) {
  return (
    error && (
      <p className="flex justify-center items-center my-2 font-bold text-cyb-pink-500">
        <Icon name="alert" className="mr-2 w-6 h-6" />
        {error}
      </p>
    )
  );
}

function TeamsLicenseCheckBannerComponent({ profileStore }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [email, setEmail] = useState('');
  const [addEmailResult, setAddEmailResult] = useState(null);

  const [verifyEmailCode, setVerifyEmailCode] = useState('');
  const [verifyEmailResult, setVerifyEmailResult] = useState(null);
  const [resendCodeMessage, setResendCodeMessage] = useState('Resend code');

  const { data: accounts } = profileStore?.profileData?.accounts || {};

  const handleOpenModal = () => {
    setIsModalOpen(true);
    // Pre-populate the email with the user's first unverified email
    const firstUnverifiedEmail = Object.values(accounts?.other || {}).find((account) => !account.verified)?.email;
    if (firstUnverifiedEmail) {
      setEmail(firstUnverifiedEmail);
    }
  };

  const handleCloseModal = () => {
    // reload profile data
    profileStore.getUserAccounts();
    setIsModalOpen(false);
    setEmail('');
    setAddEmailResult(null);
    setVerifyEmailCode('');
    setVerifyEmailResult(null);
    setError('');
    setResendCodeMessage('Resend code');
    // Send snowplow event
    trackSnowplowEvent({ category: 'TeamsLicenseCheckBanner', action: 'close', label: 'teamsLicenseCheckButton' });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    // Validate email
    if (!ValidationUtil.checkValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // See if the user already has this email in their account
    if (accounts?.primary?.email === email) {
      setError('This is your primary email address. Please use a different email address.');
      return;
    }

    const isEmailAlreadyAdded = Object.values(accounts?.other || {}).some((account) => account.email === email && account.verified);
    if (isEmailAlreadyAdded) {
      setError('This email address is already in use on your account.');
      return;
    }

    const isEmailPendingVerification = Object.values(accounts?.other || {}).some((account) => account.email === email && !account.verified);
    if (isEmailPendingVerification) {
      // Continue to let them verify this email
      setAddEmailResult(true);
      return;
    }

    setIsLoading(true);
    setError('');
    try {
      // Send snowplow event
      trackSnowplowEvent({ category: 'TeamsLicenseCheckBanner', action: 'checkTeamLicense', property: email });
      // Send to API
      const response = await agents.teamsLicenseCheck.initiateTeamJoin({ email });
      setAddEmailResult(response);
    } catch (addTeamError) {
      const errorData = addTeamError?.response?.data?.errors;
      const errorMessage = errorData?.email?.[0] || errorData?.code?.[0] || errorData?.message || addTeamError?.message || 'An error occurred. Please try again later.';
      setError(errorMessage);
      Bugsnag.notify(addTeamError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendEmail = async () => {
    try {
      setIsLoading(true);
      setError('');

      setResendCodeMessage('Sending...');
      await agents.authGoogle.verifyEmail({ email, verificationMethod: 'code' });
      setResendCodeMessage(`Code resent!`);
      // Set timeout to reset message
      setTimeout(() => {
        setResendCodeMessage('Resend code');
      }, 5000);
    } catch (e) {
      setError('An error occurred. Please try again later.');
      setResendCodeMessage('Error resending code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyEmailSubmit = async (code) => {
    // Validate email
    if (!ValidationUtil.checkValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Send snowplow event
      trackSnowplowEvent({ category: 'TeamsLicenseCheckBanner', action: 'verifyEmail', property: email });
      // Send to API
      const response = await agents.teamsLicenseCheck.verifyTeamJoin({ email, code });
      setVerifyEmailResult(response);
    } catch (addTeamError) {
      setError('Unable to verify code. Please try again.');
      Bugsnag.notify(addTeamError);
    } finally {
      setIsLoading(false);
    }
  };

  const message = useMemo(() => {
    if (verifyEmailResult === 'added_to_team') {
      return "You've been added to your company's Cybrary Team account.";
    }
    return 'If your company has a Cybrary Team account, a request has been sent to the Admin requesting a license on your behalf.';
  }, [verifyEmailResult]);

  const handleVerifyEmailCodeChange = (e) => {
    const input = e.target.value.replace(/\D/g, '').slice(0, 6);
    setVerifyEmailCode(input);
    if (input.length === 6) {
      // Auto-submit the form
      handleVerifyEmailSubmit(input);
    }
  };

  const renderModalContent = () => {
    if (verifyEmailResult) {
      return (
        <div className="flex flex-col py-8 ">
          <Header as="h2" className="flex justify-center items-center mb-8 space-x-4 text-center text-cyb-pink-600">
            <Flip duration={750}>
              <Fade duration={750}>
                <Icon name="checkmark-circle" className="mr-2 w-8 h-8" />
              </Fade>
            </Flip>
            Success!
          </Header>
          <p className="font-bold text-center">{message}</p>
        </div>
      );
    }

    if (addEmailResult) {
      return (
        <form onSubmit={() => handleVerifyEmailSubmit(verifyEmailCode)}>
          <Header as="h2" className="mb-4 text-center">
            Verify your email address.
          </Header>
          <p className="pb-0 text-center">We sent a verification code to {email}</p>
          <div className="mb-2">
            <input
              type="text"
              id="email"
              value={verifyEmailCode}
              onChange={handleVerifyEmailCodeChange}
              className="py-2 px-3 w-full rounded border"
              maxLength={6}
              pattern="\d{1,6}"
              required
            />
            <ErrorMessage error={error} />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading || !verifyEmailCode} loading={isLoading}>
            Verify Email
          </Button>
          <div className="flex justify-center">
            <AddLink onClick={handleResendEmail} type="button" className="my-2 mx-auto text-sm text-center text-cyb-pink-600">
              {resendCodeMessage}
            </AddLink>
          </div>
        </form>
      );
    }

    return (
      <form onSubmit={handleEmailSubmit}>
        <Header as="h2" className="mb-4 text-center">
          Enter your company email address.
        </Header>
        <p className="text-center">We&apos;ll check to see if licenses are available with your company.</p>
        <div className="mb-4">
          <input type="email" id="email" value={email} onChange={handleEmailChange} className="py-2 px-3 w-full rounded border" placeholder="your.name@company.com" required />
          <ErrorMessage error={error} />
        </div>
        <Button type="submit" className="w-full" disabled={isLoading || !email} loading={isLoading}>
          Check for Team License
        </Button>
      </form>
    );
  };

  return (
    <>
      <UpgradePromoBanner iconName="unlock" onClick={handleOpenModal} ctaButtonText="Check Now" spLabel="teamsLicenseCheckButton" spCategory="TeamsLicenseCheckBanner">
        <div className="flex flex-col w-128">
          <span className="block text-2xl">Unlimited access to Cybrary for FREE?</span>
          <span className="flex justify-center items-center font-light transition-all duration-300">
            <span className="group-hover:underline">Click to see if your company has licenses</span> <Icon name="arrow-right" className="ml-2 w-4 h-4" />
          </span>
        </div>
      </UpgradePromoBanner>

      <Modal size="sm" open={isModalOpen} toggle={handleCloseModal} paddingBottom="pb-4">
        <div className="px-4">
          <div className="flex justify-center items-end my-4 w-full">
            <CybraryLogo className="mx-0 mb-0" />
            <span className="mb-2 text-sm italic font-black">For Teams</span>
          </div>
          {renderModalContent()}
        </div>
      </Modal>
    </>
  );
}

const TeamsLicenseCheckBanner = inject('profileStore')(observer(TeamsLicenseCheckBannerComponent));

export default TeamsLicenseCheckBanner;
