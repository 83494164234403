import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import HeaderPromoBanner from '../../../components/Banners/HeaderPromoBanner';

/**
 * Container for immersive interstitials
 * @param {string} className - Additional classes to apply to the container
 * @param {React.ReactNode} children - The content to render inside the container
 * @returns {React.ReactNode}
 */
function InterstitialContainer({ className, children }) {
  const classes = useMemo(() => twMerge('overflow-auto h-full text-center', className), [className]);
  return (
    <div className={classes}>
      <HeaderPromoBanner inline />
      {children}
    </div>
  );
}

export default InterstitialContainer;
